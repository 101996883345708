import React, { useState, useEffect } from "react";
import axios from "axios";
import classNames from 'classnames';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
// tutorial
import Tutorial from "./Tutorial";
import img1 from "assets/img/vgf/step1.png";

// API - Client
import { client } from "Client";

import { usbColor } from "assets/jss/material-dashboard-react.jsx";

import { TypeAnimation } from 'react-type-animation';
import WheelComponent from "components/Wheel";

const style = {
  instructions: {
    textAlign: "justify",
    padding: "20px 40px",
    backgroundColor: "#eee",
    transform: "skew(-5deg)",
    fontSize: "1.1em",
    margin: "5px 30px"
  },
  badge: {
    display: "inline-block",
    color: "#fff",
    backgroundColor: usbColor,
    width: "30px",
    height: "30px",
    lineHeight: "30px",
    textAlign: "center",
    marginRight: "5px",
    borderRadius: "50px",
    fontSize: "0.8em"
  },
  formControl: {
    width: "100%"
  },
  statement: {
    margin: "20px 0",
    textAlign: "justify",
    fontSize: "1.4em",
    fontWeight: "500",
    lineHeight: "25px",
    color: "#000 !important"
  },
  radio: {
    color: usbColor,
  },
  checked: {
    color: usbColor+" !important",
  },
  option: {
    margin: "5px",
    padding: "5px 30px 5px 5px",
    textAlign: "justify",
    color: "#000",
  },
  selected: {
    backgroundColor: "#ffd8ba61"
  }
}

const tutorialSteps = [
  {
    img: img1,
    title: "Selecciona tu actividad preferida",
    description: "A continuación encontrarás un conjunto de  situaciones. Cada una de ellas presenta además diferentes actividades. Por favor selecciona la actividad que más te gustaría realizar. Tal vez algunas actividades no sean exactamente tus favoritas, pero es importante que identifiques aquella por la que sientes mayor afinidad."
  }
]

const CancelToken = axios.CancelToken;

const useStyles = makeStyles(style);

export default function Step1(props) {
  const classes = useStyles();

  const { values, handleProgress, handleChange, onSubmit } = props;

  const [isTutorial, setIsTutorial] = useState(true);
  const [isRoulette, setIsRoulette] = useState(true);
  const [displayOptions, setDisplayOptions] = useState(false);

  const [statements, setStatements] = useState([]);
  const [currentStatement, setCurrentStatement] = useState(0);

  // The last statement should be 'statements.length - 1' but there is an optional tie-breaker statement, so the last one is actually 'statements.length - 2'
  const isLastStatement = currentStatement >= statements.length - 2;

  const statementId = statements[currentStatement]?.id;
  const statement = statements[currentStatement]?.statement;
  const statementNumber = currentStatement + 1;
  const options = statements[currentStatement]?.options;
  const value = values[statementId] ?? null;

  useEffect(() => {
    const source = CancelToken.source();
    const axiosCancelToken = source.token;

    client.getVGFAreaStatements(axiosCancelToken).then(({ area_statements }) => {
      // Order statements by id because they arrive shuffled
      area_statements.sort((a, b) => {
        return a.id - b.id;
      });

      setStatements(area_statements);

    }).catch(error => console.log( error ))

    return () => source.cancel();
  }, []);

  const getWinningArea = () => {
    // Student selected options (ids)
    const choicedOptionIds = Object.values(values);

    // If no options have been selected yet return null
    if( choicedOptionIds.length === 0 ) return null;
    
    // Get areaIds related to selected options
    const areaIds = statements.map(({ options }) => 
      options.find(({ option_id }) => choicedOptionIds.includes(option_id))?.area
    );

    // Counts for each areaID
    const counts = areaIds.reduce((acc, cur) => {
      acc[cur] = (acc[cur] || 0) + 1;
      return acc;
    }, {});

    const maxCount = Math.max(...Object.values(counts));

    // Threshold: 35% of 20 is 7. 20 is the total statements (without tie-breaker)
    if(maxCount < 7) return []

    const mostFrequent = Object.keys(counts).filter(k => counts[k] === maxCount).map(Number);
    
    return mostFrequent
  }

  const tieBreaker = (between) => {
    // Tie-breaker statement only shows options for tied areas
    setStatements(prev => {
      const last = prev.pop()
      return [
        ...prev,
        {
          ...last,
          options: last.options.filter(({ area }) => between.includes(area))
        }
      ]
    })

    setCurrentStatement(prev => prev + 1)
    handleProgress(currentStatement, statements.length)
  }

  const handleTutorialComplete = () => {
    setIsTutorial(false);
  }

  const handleButton = () => {
    if(isLastStatement) {
      const winning = getWinningArea();
      // If there's more than one winning area show tie-breaker statement
      // else if areaId exists send it into onSubmit callback or send null if there isn't an area that passes the threshold
      if ( winning.length > 1 ) {
        setIsRoulette(true);
        setDisplayOptions(false);

        tieBreaker(winning)
      } else {
        onSubmit(winning[0] ?? null)
      }
    } else {
      setIsRoulette(true);
      setDisplayOptions(false);

      setCurrentStatement(prev => prev + 1)
      handleProgress(currentStatement, statements.length)
    }
  }

  const onFinishedRoulette = (winner) => {
    setIsRoulette(false);
  }

  return (
    <div>
      {isTutorial
        ? <Tutorial steps={tutorialSteps} onComplete={handleTutorialComplete} />
        : isRoulette
        ? <WheelComponent onFinished={onFinishedRoulette} />
        : !statements.length
          ? "Cargando ..." 
          :
          <div>
            <h4 className={classes.instructions}>A continuación, encontrarás unas afirmaciones relacionadas con diferentes profesiones. Queremos que las leas cuidadosamente y respondas seleccionado la actividad que más te gusta.</h4>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel className={classes.statement}>
                <div className={classes.badge}>{statementNumber}</div>
                <TypeAnimation
                  sequence={[
                    statement,
                    () => {
                      setDisplayOptions(true);
                    },
                  ]}
                  speed={99}
                />
              </FormLabel>
              {displayOptions && 
                <RadioGroup aria-label={statement} value={value} onChange={e => handleChange(statementId, e.target.value)}>
                  {options.map(({ option_id, option, area }) => (
                    <FormControlLabel
                      key={option_id}
                      value={option_id}
                      control={<Radio classes={{root: classes.radio, checked: classes.checked}} />}
                      label={<TypeAnimation cursor={false} sequence={[option, () => {}]} />}
                      // label={option}
                      className={classNames({[classes.option]: true, [classes.selected]: !value || value === option_id})}
                    />
                  ))}
                </RadioGroup>
              }
            </FormControl>
            <GridContainer justify="center">
              <GridItem xs={12} sm={8} md={8}>
                <Button variant="contained" className={classes.button} color="usb" size="lg" fullWidth onClick={handleButton} disabled={!value}>
                  {isLastStatement ? '¡Ir a la Etapa 2!' : 'Siguiente'}
                </Button>
              </GridItem>
            </GridContainer>
          </div>
      }
    </div>
  );
}