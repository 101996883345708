import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
// custom components
import Form from "./Form.js";
import VGFReport from "./VGFReport.js";
// images
import professions from "assets/img/vgf/professions.png";
import usb from "assets/img/usb.png";

import styles from "assets/jss/material-dashboard-react/views/VocationalGuidanceFormStyles.js";

import { client } from "../../Client.js";

const useStyles = makeStyles(styles);

export default function VocationalGuidanceForm() {
    
  const classes = useStyles();

  const [code, setCode] = useState('');
  const [error, setError] = useState(null);
  const [stage, setStage] = useState(0);
  const [results, setResults] = useState({});

  const handleCode = () => {
    client.redeemCode(code, (err, res) => {
      if(err) return console.log( err )

      res.ok? setStage(prev => prev + 1) : setError(res.message)
    });
  }

  const handleChange = e => {
    setError(null);
    setCode(e.target.value.trim())
  }

  const onSubmit = results => {
    setResults(results);
    setStage(prev => prev + 1);
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <GridContainer justifyContent="center">
          <GridItem xs={12} sm={8}>
            <Card>
              <CardBody>
                {stage === 0 &&
                  <div>
                    <div className={classes.titleContainer}>
                      <h1 className={classes.title}>BRÚJULA</h1>
                      <h3 className={classes.subtitle}>Prueba de intereses vocacionales</h3>
                    </div>
                    <GridContainer alignItems="flex-end" justifyContent="flex-end">
                      <GridItem xs={12} sm={12} md={4} className={classes.floatBox}>
                        <Card className={classes.card}>
                          <CardBody>
                            <p>La elección de carrera es una de las decisiones más importantes de la vida. Por eso queremos acompañarte en este momento y ponemos a  tu disposición nuestra Prueba de Orientación Vocacional. Te invitamos a que la respondas ahora mismo, y descubras las carreras más afines a tu perfil. Esperamos que los resultados de nuestra prueba te sean de utilidad en esta importante decisión que estás a punto de tomar.</p>
                          </CardBody>
                        </Card>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={10}>
                        <img src={professions} className={classes.img} alt=""/>
                      </GridItem>
                    </GridContainer>
                    <GridContainer justifyContent="center">
                      <GridItem xs={12} sm={8} md={6}>
                        <CustomInput
                          labelText="Código de prueba"
                          id="code"
                          value={code}
                          onChange={handleChange}
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            name: "code"
                          }}
                          error={error}
                        />
                        <Button variant="contained" className={classes.button} color="usb" size="lg" fullWidth onClick={handleCode}>
                          Iniciar prueba
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </div>
                }
                {stage === 1 &&
                  <Form testCode={code} onSubmit={onSubmit} />
                }
                {stage === 2 &&
                  <VGFReport data={results} email={true}/>
                }
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <div className={classes.logosContainer}>
          <img src={usb} className={classes.floatLogo} alt="logo usb" />
        </div>
      </div>
    </div>
  );
}