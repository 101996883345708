import React, { Component, Fragment } from "react";
import axios from "axios";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Table from "components/Table/Table.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import AlertDialog from "components/AlertDialog/AlertDialog.jsx";
import InstitutionForm from "components/InstitutionForm/InstitutionForm.jsx";
// API - Client
import { client } from "../../Client";
//Helpers
import { isEmail } from "../../Helpers";

const CancelToken = axios.CancelToken;
let source;
let axiosCancelToken;

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class Institutions extends Component {
  
  state = {
    institutions: [],
    selectedInstitution: {},
    fieldErrors: {},
    cities: [],
    types: [],
    _showEditDialog: false,
    _showRemoveDialog: false,
    _isLoading: false
  }

  componentDidMount() {
    source = CancelToken.source();
    axiosCancelToken = source.token;

    this.getInstitutions()
    this.getCities()
    this.getInstitutionTypes()
  }

  componentWillUnmount() {
    source.cancel();
  }

  getInstitutions = () => {
    this.setState({ _isLoading: true })
    client.getInstitutions(axiosCancelToken).then((data) => {

      let institutions = data.map(({ id, name, responsible, phone, email, city, institution_type }) => {
        return {
          id,
          name,
          responsible,
          phone,
          email,
          city,
          type: institution_type
        }
      })

      this.setState({ institutions, _isLoading: false })
    }).catch(error => {});
  }

  getCities = () => {
    client.getCities(axiosCancelToken).then(cities => {
      this.setState({ cities })
    }).catch(error => {})
  }

  getInstitutionTypes = () => {
    client.getInstitutionTypes(axiosCancelToken).then(types => {
      this.setState({ types })
    }).catch(error => {})
  }

  handleChange = (evt) => {
    const name = evt.target.name;
    const value = evt.target.value;

    this.setState(prevState => ({
      selectedInstitution: {
        ...prevState.selectedInstitution,
        [name]: value
      }
    }));
  }

  handleCloseDialog = () => {
    this.setState({ _showRemoveDialog: false, _showEditDialog: false })
  }

  /**
   * REMOVE INSTITUTION FLOW
   */
  onRemove = (institution) => {
    this.setState({ _showRemoveDialog: true, selectedInstitution: institution })
  }
  
  handleRemoveDialog = () => {
    this.removeInstitution()
    this.setState({ _showRemoveDialog: false })
  }

  removeInstitution = () => {
    let institutionId = this.state.selectedInstitution.id;
    client.removeInstitution(institutionId).then(({ ok }) => {
      if (ok) {
        this.setState({
          institutions: this.state.institutions.filter(({ id }) => id !== institutionId)
        })
      }
    })
  }

  /**
   * EDIT INSTITUTION FLOW
   */

   onEdit = (institution) => {
    this.setState({
      _showEditDialog: true,
      selectedInstitution: {
        ...institution,
        city: institution.city.id,
        type: institution.type.id
      }
    })
   }

   handleEditDialog = () => {
    this.editInstitution()
   }

   editInstitution = () => {
    let selectedInstitution = this.state.selectedInstitution
    const fieldErrors = this.validate(selectedInstitution)
    this.setState({ fieldErrors })
    if( Object.keys(fieldErrors).length ) return;

    client.updateInstitution(selectedInstitution).then(({ ok }) => {
      if (ok) {
        this.setState({
          _showEditDialog: false,
          institutions: this.state.institutions.map(institution => {
            if (institution.id === selectedInstitution.id) {
              return {
                ...selectedInstitution,
                city: this.state.cities.find(({ id }) => id === selectedInstitution.city),
                type: this.state.types.find(({ id }) => id === selectedInstitution.type)
              }
            }
            return institution
          })
        })
      }
    }).catch(({ response }) => {
      const fieldErrors = this.getFormErrors(response.data.message)
      this.setState({ fieldErrors })
    })
   }

   validate = (form) => {
    const errors = {};
    if (!form.name) errors.name = 'Nombre requerido';
    if (!form.responsible) errors.responsible = 'Responsable requerido';
    if (!form.phone) errors.phone = 'Teléfono requerido';
    if (!form.email) errors.email = 'Correo requerido';
    if (!form.city) errors.city = 'Ciudad requerida';
    if (!form.type) errors.type = 'Tipo requerido';
    if (form.email && !isEmail(form.email)) errors.email = 'Correo inválido';
    return errors;
  };

  getFormErrors = (messages) => {
    const errors = {}
    for (const key in messages) {
      if (messages.hasOwnProperty(key)) {
        errors[key] = messages[key][0]        
      }
    }
    return errors
  }

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="warning">
                <h4 className={classes.cardTitleWhite}>Instituciones</h4>
                <p className={classes.cardCategoryWhite}>
                  Listado de instituciones existentes
                </p>
              </CardHeader>
              <CardBody>
                <Table
                  tableHeaderColor="warning"
                  tableHead={["ID", "Nombre", "Responsable", "Teléfono", "Correo", "Ciudad", "Tipo"]}
                  tableData={this.state.institutions}
                  onRemove={this.onRemove}
                  onEdit={this.onEdit}
                  loading={this.state._isLoading}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <AlertDialog
          open={this.state._showRemoveDialog}
          onClose={this.handleCloseDialog}
          onAgree={this.handleRemoveDialog}
          dialogTitle={`${this.state.selectedInstitution.name}`}
          dialogDescription={`¿Está seguro que desea eliminar esta institución?. Esta acción es irreversible`}
          remove
        />
        <AlertDialog
          open={this.state._showEditDialog}
          onClose={this.handleCloseDialog}
          onAgree={this.handleEditDialog}
          dialogTitle={`${this.state.selectedInstitution.name}`}
          dialogContent={
            <InstitutionForm form={this.state.selectedInstitution} onChange={this.handleChange}
              cities={this.state.cities} types={this.state.types} fieldErrors={this.state.fieldErrors} />
          }
          dialogProps={{
            fullWidth: true,
            maxWidth: "md"
          }}
        />
      </Fragment>
    );
  }
}

export default withStyles(styles)(Institutions);
