import React, { Component } from "react";
import axios from "axios";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import PlayerForm from "../../components/PlayerForm/PlayerForm";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
// API - Client
import { client } from "../../Client";

const CancelToken = axios.CancelToken;
let source;
let axiosCancelToken;

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

class AddPlayer extends Component {
  state ={
    form: {
      username: "",
      password: "",
      name: "",
      lastName: "",
      birthdate: "",
      scholarship: "",
      genre: "",
      communication: "",
      institution: "",
      auditoryLossMoment: "",
      auditoryLossGrade: "",
      diagnoses: []
    },
    fieldErrors: {},
    scholarships: [],
    genres: [],
    communications: [],
    institutions: [],
    auditoryLossMoments: [],
    auditoryLossGrades: [],
    diagnoses: [],
    _savedNotification: false,
    _lastAdded: ""
  }

  componentDidMount() {
    source = CancelToken.source();
    axiosCancelToken = source.token;
    
    this.getScholarships()
    this.getGenres()
    this.getCommunications()
    this.getInstitutions()
    this.getAuditoryLossMoments()
    this.getAuditoryLossGrades()
    this.getDiagnoses()
  }

  componentWillUnmount() {
    source.cancel();
  }

  getScholarships = () => {
    client.getScholarships(axiosCancelToken).then(scholarships => {
      this.setState({ scholarships })
    }).catch(error => {})
  }

  getGenres = () => {
    client.getGenres(axiosCancelToken).then(genres => {
      this.setState({ genres })
    }).catch(error => {})
  }

  getCommunications = () => {
    client.getCommunications(axiosCancelToken).then(communications => {
      this.setState({ communications })
    }).catch(error => {})
  }

  getInstitutions = () => {
    client.getInstitutions(axiosCancelToken).then(institutions => {
      this.setState({ institutions })
    }).catch(error => {})
  }

  getAuditoryLossMoments = () => {
    client.getAuditoryLossMoments(axiosCancelToken).then(auditoryLossMoments => {
      this.setState({ auditoryLossMoments })
    }).catch(error => {})
  }

  getAuditoryLossGrades = () => {
    client.getAuditoryLossGrades(axiosCancelToken).then(auditoryLossGrades => {
      this.setState({ auditoryLossGrades })
    }).catch(error => {})
  }

  getDiagnoses = () => {
    client.getDiagnoses(axiosCancelToken).then(diagnoses => {
      this.setState({ diagnoses })
    }).catch(error => {})
  }

  handleChange = (evt) => {
    const name = evt.target.name;
    const value = evt.target.value;

    this.setState(prevState => ({
      form: {
        ...prevState.form,
        [name]: value
      }
    }));

    if ( name === "communication" && value === 4 ) this.resetAuditoryFields()
  }

  resetAuditoryFields = () => {
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        auditoryLossMoment: "",
        auditoryLossGrade: ""
      }
    }));
  }

  validate = (form) => {
    const errors = {};
    if (!form.username) errors.username = 'Usuario requerido';
    if (!form.password) errors.password = 'Contraseña requerida';
    if (!form.name) errors.name = 'Nombre requerido';
    if (!form.lastName) errors.lastName = 'Apellido requerido';
    if (!form.birthdate) errors.birthdate = 'Fecha de nacimiento requerida';
    if (!form.scholarship) errors.scholarship = 'Escolaridad requerida';
    if (!form.genre) errors.genre = 'Género requerido';
    if (!form.communication) errors.communication = 'Tipo de comunicación requerida';
    if (!form.institution) errors.institution = 'Institución requerida';
    return errors;
  };

  getFormErrors = (messages) => {
    const errors = {}
    for (const key in messages) {
      if (messages.hasOwnProperty(key)) {
        errors[key] = messages[key][0]        
      }
    }
    return errors
  }

  handleSubmit = () => {
    const form = this.state.form;
    const fieldErrors = this.validate(form)
    this.setState({ fieldErrors })
    if( Object.keys(fieldErrors).length ) return;

    client.AddPlayer(this.state.form).then(({ data }) => {
      if ( data.ok ) {
        this.setState(prevState => ({
          form: {
            username: "",
            password: "",
            name: "",
            lastName: "",
            birthdate: "",
            scholarship: "",
            genre: "",
            communication: "",
            institution: "",
            auditoryLossMoment: "",
            auditoryLossGrade: "",
            diagnoses: []
          },
          _lastAdded: prevState.form.username
        }))

        this.showNotification();
      }
    }).catch(({ response }) => {
      const fieldErrors = this.getFormErrors(response.data.message)
      this.setState({ fieldErrors })
    })
  }

  showNotification = () => {
    this.setState({ _savedNotification: true })

    setTimeout(() => {
      this.setState({ _savedNotification: false })
    }, 3000)
  }

  render () {

    const { classes } = this.props;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success">
                <h4 className={classes.cardTitleWhite}>Nuevo estudiante</h4>
                <p className={classes.cardCategoryWhite}>Complete la información y guarde para añadir un nuevo estudiante</p>
              </CardHeader>
              <CardBody>
                <PlayerForm form={this.state.form} onChange={this.handleChange} scholarships={this.state.scholarships}
                  genres={this.state.genres} communications={this.state.communications} institutions={this.state.institutions}
                  auditoryLossMoments={this.state.auditoryLossMoments} auditoryLossGrades={this.state.auditoryLossGrades}
                  diagnoses={this.state.diagnoses} fieldErrors={this.state.fieldErrors} />
              </CardBody>
              <CardFooter>
                <Button color="success" onClick={this.handleSubmit} disabled={this.state.form.username === ""} >Guardar</Button>
                <Snackbar
                  place="br" // Bottom Right
                  color="success"
                  icon={CheckCircleOutline}
                  message={`El usuario '${this.state._lastAdded}' fue agregado correctamente!`}
                  open={this.state._savedNotification}
                  closeNotification={() => this.setState({ _savedNotification: false })}
                  close
                />
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(styles)(AddPlayer);
