import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import FormHelperText from '@material-ui/core/FormHelperText';
// core components
import customInputStyle from "assets/jss/material-dashboard-react/components/customInputStyle.jsx";

function CustomDatepicker({ ...props }) {
  const {
    classes,
    formControlProps,
    labelText,
    inputProps,
    id,
    value,
    onChange,
    error
  } = props;

  return (
    <FormControl
      {...formControlProps}
      className={formControlProps.className + " " + classes.formControl}
      error={error}
    >
      <TextField
        id={id}
        label={labelText}
        type="date"
        value={value}
        onChange={onChange}
        InputLabelProps={{
          shrink: true,
        }}
        {...inputProps}
      />
      {error ? (
        <FormHelperText id="component-error-text">{error}</FormHelperText>
      ) : null}
    </FormControl>
  );
}

CustomDatepicker.propTypes = {
  classes: PropTypes.object.isRequired,
  labelText: PropTypes.node,
  id: PropTypes.string,
  defaultValue: PropTypes.string,
  formControlProps: PropTypes.object
};

export default withStyles(customInputStyle)(CustomDatepicker);
