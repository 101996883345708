import React from "react";
// images
import usb from "assets/img/usb.jpg";
import labpsicologia from "assets/img/labpsicologia.jpg";
// PDF
import {
  Page,
  Text,
  Image,
  View,
  Document,
  StyleSheet,
  Canvas,
  Link,
} from "@react-pdf/renderer";
import { usbColor } from "../../assets/jss/material-dashboard-react";

// A4: [595.28, 841.89]
const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  title: {
    fontSize: 15,
    fontWeight: 800,
  },
  name: {
    fontSize: 12,
    fontWeight: 800,
    marginTop: 5,
  },
  info: {
    fontSize: 10,
    fontWeight: 100,
    marginTop: 5,
  },
  bold: {
    fontWeight: 800,
  },
  logosContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  logo: {
    width: 80,
  },
  divider: {
    width: 525.28,
    height: 10,
    marginBottom: 10,
  },
  areaSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  areaContainer: {
    width: 300.4,
  },
  areaLogoTitleContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  areaBadge: {
    width: 40,
    fontSize: 10,
    paddingVertical: 5,
    paddingHorizontal: 10,
    color: "#fff",
    backgroundColor: usbColor,
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
    borderBottomRightRadius: 3,
    borderBottomLeftRadius: 3,
  },
  areaInfoContainer: {
    marginTop: 3,
  },
  graph: {
    width: 200,
    height: "auto",
  },
  resultsContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  resultsHeader: {
    width: 160,
    fontSize: 15,
    fontWeight: 400,
    color: "#fff",
    backgroundColor: usbColor,
    textAlign: "center",
    paddingVertical: 5,
  },
  scoreBox: {
    width: 80,
    paddingVertical: 15,
    paddingHorizontal: 3,
    backgroundColor: "#f5f5f5",
  },
  scoresContainer: {
    display: "flex",
    flexDirection: "row",
  },
  resultsScore: {
    fontSize: 20,
    fontWeight: 100,
    textAlign: "center",
  },
  resultsArea: {
    fontSize: 10,
    textAlign: "center",
    marginTop: 3,
  },
  programsSectionTitle: {
    fontSize: 13,
  },
  programContainer: {
    marginTop: 10,
  },
  programTitle: {
    position: "absolute",
    fontSize: 12,
    paddingVertical: 5,
    paddingHorizontal: 10,
    color: "#fff",
    backgroundColor: usbColor,
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
    borderBottomRightRadius: 3,
    borderBottomLeftRadius: 3,
  },
  programDescription: {
    marginTop: 35,
    fontSize: 10,
    textAlign: "justify",
  },
  programUrl: {
    fontSize: 10,
    textAlign: "justify",
  },
  footerContainer: {
    marginTop: 35,
  },
  footerText: {
    fontSize: 10,
    textAlign: "center",
  },
});

const getProgramMessage = (programs) => {
  const groupsIds = programs?.map(({ id }) => id);
  const isMultipleGroups = new Set(groupsIds).size > 1;

  if (isMultipleGroups) {
    return "Nuestra prueba detectó inclinación hacia varios grupos de carreras. Sin embargo, no encontramos ninguna que se destaque. Parece que tienes unos gustos muy amplios. Te sugerimos consultar con un profesional especializado en orientación vocacional y profesional.";
  } else {
    return "Felicitaciones, nuestra prueba detectó una inclinación muy clara por este grupo de carreras.";
  }
};

export default function Pdf(props) {
  const {
    data: { threshold, student, areas, programs, graph, historyMessage },
  } = props;

  const areaThreshold = threshold?.code === 1;

  const history = () => {
    return (
      <View>
        <Canvas
          style={styles.divider}
          paint={(painter, width) =>
            painter.moveTo(0, 10).lineTo(width, 10).stroke()
          }
        />
        <View>
          <Text style={styles.programsSectionTitle}>Histórico:</Text>
          <Text style={styles.info}>{historyMessage}</Text>
        </View>
      </View>
    );
  };

  const area = () => {
    const bestArea = areas.reduce((prev, current) =>
      prev.score > current.score ? prev : current
    );

    return (
      <View style={styles.areaSection}>
        {!areaThreshold ? (
          <View style={styles.areaContainer}>
            <View>
              <Text style={styles.areaBadge}>Área</Text>
              <Text>{bestArea?.area}</Text>
            </View>
            <View style={styles.areaInfoContainer}>
              <Text style={styles.info}>{bestArea?.description}</Text>
            </View>
          </View>
        ) : (
          <View style={styles.areaContainer}>
            <Text style={styles.info}>
              Nuestra prueba no detectó ninguna inclinación definida en tus
              respuestas. Te sugerimos consultar con un profesional
              especializado en orientación vocacional y profesional.
            </Text>
          </View>
        )}
        <View style={styles.resultsContainer}>
          <Image style={styles.graph} src={graph} />
        </View>
      </View>
    );
  };

  const groups = () => {
    const programThreshold = threshold?.code === 2;

    return (
      <View>
        <Canvas
          style={styles.divider}
          paint={(painter, width) =>
            painter.moveTo(0, 10).lineTo(width, 10).stroke()
          }
        />
        <View>
          <Text style={styles.programsSectionTitle}>Programas sugeridos:</Text>
          {programThreshold ? (
            <Text style={styles.info}>
              Nuestra prueba no detectó ninguna inclinación definida en tus
              respuestas de profundización. Te sugerimos consultar con un
              profesional especializado en orientación vocacional y profesional.
            </Text>
          ) : (
            <View>
              <Text style={styles.info}>{getProgramMessage(programs)}</Text>
              <View>
                {programs?.map((program, index) => (
                  <View key={index} style={styles.programContainer}>
                    <Text style={styles.programTitle}>{program.name}</Text>
                    <Text style={styles.programDescription}>
                      {program.description}
                    </Text>
                    {program.url && (
                      <Text style={styles.programUrl}>
                        Conoce más sobre este programa aquí:{" "}
                        <Link src={program.url}>{program.url}</Link>
                      </Text>
                    )}
                  </View>
                ))}
              </View>
            </View>
          )}
        </View>
      </View>
    );
  };

  return (
    <Document>
      <Page size="A4" style={styles.body}>
        <View style={styles.header}>
          <View>
            <Text style={styles.title}>
              Brújula: Prueba de intereses vocacionales
            </Text>
            <Text
              style={styles.name}
            >{`${student.name} ${student.last_name}`}</Text>
            <Text style={styles.info}>Grado: {student.scholarship}</Text>
            <Text style={styles.info}>Edad: {student.age}</Text>
            <Text style={styles.info}>Institución: {student.institution}</Text>
          </View>
          <View style={styles.logosContainer}>
            <Image src={labpsicologia} style={styles.logo} />
            <Image src={usb} style={styles.logo} />
          </View>
        </View>
        {historyMessage && history()}
        <Canvas
          style={styles.divider}
          paint={(painter, width) =>
            painter.moveTo(0, 10).lineTo(width, 10).stroke()
          }
        />
        {area()}
        {!areaThreshold && groups()}
        <Canvas
          style={styles.divider}
          paint={(painter, width) =>
            painter.moveTo(0, 10).lineTo(width, 10).stroke()
          }
        />
        <View style={styles.footerContainer}>
          <Text style={styles.footerText}>
            Estudiar en la Universidad de San Buenaventura Cali ES POSIBLE
            formando PROFESIONALES CON FUTURO.
          </Text>
          <Text style={styles.footerText}>
            Conoce nuestras becas en{" "}
            <Link src="https://www.usbcali.edu.co/mas-beneficios/becas">
              https://www.usbcali.edu.co/mas-beneficios/becas
            </Link>
          </Text>
        </View>
      </Page>
    </Document>
  );
}
