import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import NavPills from "components/NavPills/NavPills.js";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";

// images
import labpsicologia from "assets/img/labpsic.png";
import visor from "assets/img/visor.png";
import vgf from "assets/img/vgf.png";
import becs from "assets/img/becs.png";
import usb from "assets/img/usb.png";
import profuturo from "assets/img/profuturo.png";

import { cardTitle, container } from "assets/jss/material-dashboard-react.jsx";
import { Link } from "react-router-dom";


const useStyles = makeStyles(theme => ({
  wrapper: {
    background: "linear-gradient(180deg, rgba(222,243,248,1) 20%, rgba(255,255,255,1) 100%)",
    position: "relative",
    top: "0",
    minHeight: "100vh"
  },
  container,
  cardTitle: {
    ...cardTitle,
    fontWeight: "600"
  },
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center",
    margin: "0",
    fontWeight: "400"
  },
  cardCategory: {
    margin: "0",
    color: "#999999"
  },
  description: {
    fontSize: "1.2em"
  },
  adminLink: {
    position: "absolute",
    right: "10px"
  },
  logo: {
    textAlign: "center"
  },
  labLogo: {
    width: "60%",
    [theme.breakpoints.up('sm')]: {
      width: "30%"
    }
  },
  logosContainer: {
    display: "flex",
    justifyContent: "space-between"
  },
  floatLogo: {
    position: "relative",
    opacity: "0.6",
    maxHeight: "105px",
    [theme.breakpoints.up('md')]: {
      position: "fixed",
      bottom: "10px",
      maxWidth: "10%"
    },
  },
  right: {
    right: "10px"
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    maxHeight: "41px"
  }
}));

export default function Index() {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <Link to="/login" className={classes.adminLink}>Admin</Link>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <div className={classes.logo}>
              <img src={labpsicologia} className={classes.labLogo} alt=""/>
            </div>
            <NavPills
              color="info"
              alignCenter
              tabs={[
                {
                  tabButton: "VISOR 2.0",
                  tabImage: visor,
                  tabContent: (
                    <Card>
                      <CardHeader>
                        <GridContainer justify="space-around">
                          <GridItem xs={12} sm={8} md={8}>
                            <h3 className={classes.cardTitle}>
                              VISOR 2.0
                            </h3>
                            <h4 className={classes.cardCategory}>
                              {/* More information here */}
                            </h4>
                          </GridItem>
                          <GridItem xs={12} sm={4} md={4} className={classes.buttonContainer}>
                            <Button
                              color="info"
                              className={classes.button}
                              onClick={() => {}}>Descargar</Button>
                          </GridItem>
                        </GridContainer>
                      </CardHeader>
                      <CardBody className={classes.description}>
                        Visor 2.0 es un videojuego de aventura diseñado para evaluación cognitiva y educación
                        para la paz. La historia ocurre en una antigua tierra mágica, donde una terrible maldición
                        transformó a los habitantes  en muertos vivientes. Los padres del protagonista son infectados
                        y la misión consiste es buscar un antídoto que salve a su familia y a los aldeanos. Incluye
                        una adaptación para niños sordos, para lo cual se presentan las consignas de las tareas en
                        Lengua de Señas Colombiana.
                      </CardBody>
                    </Card>
                  )
                },
                {
                  tabButton: "BECS",
                  tabImage: becs,
                  tabContent: (
                    <Card>
                      <CardHeader>
                        <GridContainer justify="space-around">
                          <GridItem xs={12} sm={8} md={8}>
                            <h3 className={classes.cardTitle}>
                              BECS
                            </h3>
                            <h4 className={classes.cardCategory}>
                              Batería para la cognición social
                            </h4>
                          </GridItem>
                          <GridItem xs={12} sm={4} md={4} className={classes.buttonContainer}>
                            <Button
                              color="info"
                              className={classes.button}
                              onClick={() => {}}>Descargar</Button>
                          </GridItem>
                        </GridContainer>
                      </CardHeader>
                      <CardBody className={classes.description}>
                        Se trata de un instrumento en formato multimedia para evaluar diferentes aspectos de la
                        cognición social (reconocimiento de emociones, teoría de la mente y empatía). Está diseñado
                        para ser utilizado en dispositivos móviles (tablets). Incluye una adaptación para niños sordos,
                        para lo cual se presentan las consignas de las tareas en Lengua de Señas Colombiana.
                      </CardBody>
                    </Card>
                  )
                },
                {
                  tabButton: "BRÚJULA",
                  tabImage: vgf,
                  tabContent: (
                    <Card>
                      <CardHeader>
                        <GridContainer justify="space-around">
                          <GridItem xs={12} sm={8} md={8}>
                            <h3 className={classes.cardTitle}>
                              BRÚJULA
                            </h3>
                            <h4 className={classes.cardCategory}>
                              PRUEBA DE INTERESES VOCACIONALES
                            </h4>
                          </GridItem>
                          <GridItem xs={12} sm={4} md={4} className={classes.buttonContainer}>
                            <Link to="/prueba-orientacion-vocacional">
                              <Button color="info">Ir</Button>
                            </Link>
                          </GridItem>
                        </GridContainer>
                      </CardHeader>
                      <CardBody className={classes.description}>
                        La prueba de Orientación Vocacional está diseñada para apoyar una de las decisiones
                        más importantes y determinantes para la construcción del proyecto de vida: la elección
                        de carrera. Permite a los  participantes la identificación de intereses profesionales y
                        el descubrimiento de las carreras más afines al perfil personal.
                      </CardBody>
                    </Card>
                  )
                }
              ]}
            />
          </GridItem>
        </GridContainer>
        <div className={classes.logosContainer}>
          <img src={usb} className={classes.floatLogo} alt="logo usb" />
          <img src={profuturo} className={classes.floatLogo + ' ' + classes.right} alt="logo profesionales con futuro" />
        </div>
      </div>
    </div>
  );
}
