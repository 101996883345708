import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import FormHelperText from '@material-ui/core/FormHelperText';
// core components
import customInputStyle from "assets/jss/material-dashboard-react/components/customInputStyle.jsx";

function CustomMultipleSelect({ ...props }) {
  const {
    classes,
    formControlProps,
    labelText,
    id,
    labelProps,
    selectProps,
    menuItems,
    value,
    onChange,
    error
  } = props;

  const marginTop = classNames({
    [classes.marginTop]: labelText === undefined
  });

  return (
    <FormControl
      {...formControlProps}
      className={formControlProps.className + " " + classes.formControl}
      error={error}
    >
      {labelText !== undefined ? (
        <InputLabel
          className={classes.labelRoot}
          htmlFor={id}
          {...labelProps}
        >
          {labelText}
        </InputLabel>
      ) : null}
      <Select
        multiple
        classes={{
          root: marginTop,
          disabled: classes.disabled
        }}
        id={id}
        value={value}
        renderValue={selected => selected.join(', ')}
        onChange={onChange}
        {...selectProps}
      >
        {menuItems.map(({ id, name }) => {
          return (
            <MenuItem key={id} value={name}>
              <Checkbox checked={value.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          );
        })}
      </Select>
      {error ? (
        <FormHelperText id="component-error-text">{error}</FormHelperText>
      ) : null}
    </FormControl>
  );
}

CustomMultipleSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  selectProps: PropTypes.object,
  menuItems: PropTypes.array,
  formControlProps: PropTypes.object,
  checked: PropTypes.array
};

export default withStyles(customInputStyle)(CustomMultipleSelect);
