import React from 'react';
import PropTypes from "prop-types";
// @material-ui/core components
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

function AlertDialog({ ...props }) {
  const { 
    open,
    onClose,
    onAgree,
    dialogTitle,
    dialogDescription,
    dialogProps,
    dialogContent,
    buttonProps,
    closeButtonProps,
    agreeButtonProps,
    remove
  } = props

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="max-width-dialog-title"
      aria-describedby="alert-dialog-description"
      {...dialogProps}
    >
      {!!dialogTitle && <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>}
      <DialogContent>
        {!!dialogDescription && 
          <DialogContentText id="alert-dialog-description">{dialogDescription}</DialogContentText>
        }
        {!!dialogContent && dialogContent}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} {...buttonProps} {...closeButtonProps}>
          Cancelar
        </Button>
        <Button onClick={onAgree} {...buttonProps} {...agreeButtonProps} color={remove? "secondary" : "primary"} autoFocus>
          {remove ? 'Borrar' : 'Aceptar'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

AlertDialog.defaultProps = {
  open: false,
  closeButtonProps: {
    color: "primary"
  }
}

AlertDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onAgree: PropTypes.func.isRequired,
  dialogTitle: PropTypes.string,
  dialogDescription: PropTypes.string,
  dialogContent: PropTypes.element,
  remove: PropTypes.bool
}

export default AlertDialog