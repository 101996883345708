import React, { Component } from "react";
import { Redirect } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Info from "@material-ui/icons/Info";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import FormHelperText from "@material-ui/core/FormHelperText";
import CircularProgress from "@material-ui/core/CircularProgress";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
// API - Client
import { client } from "../../Client";

import styles from "assets/jss/material-dashboard-react/views/login.js";

// Internet Explorer 6-11
var isIE = /*@cc_on!@*/ false || !!document.documentMode;
// Edge 20+
const isEdge = !isIE && !!window.StyleMedia;
// Firefox 1.0+
const isFirefox = typeof InstallTrigger !== "undefined";
// Chrome
const isChromium = window.chrome;
const winNav = window.navigator;
const vendorName = winNav.vendor;
const isOpera = typeof window.opr !== "undefined";
const isIEedge = winNav.userAgent.indexOf("Edge") > -1;
const isIOSChrome = winNav.userAgent.match("CriOS");

class Login extends Component {
  state = {
    form: {
      username: "",
      password: "",
    },
    _isLoading: false,
    error: null,
    shouldRedirect: false,
  };

  handleChange = (evt) => {
    if (this.state.error) {
      this.setState({ error: null });
    }

    const name = evt.target.name;
    const value = evt.target.value;

    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [name]: value,
      },
    }));
  };

  handleKeyPress = (evt) => {
    if (evt.keyCode === 13) this.handleSubmit();
  };

  handleSubmit = () => {
    this.setState({ _isLoading: true });

    client
      .getToken(this.state.form)
      .then(() => this.setState({ shouldRedirect: true }))
      .catch(({ response }) => {
        let errorMessage =
          "Hubo un error inesperado. Por favor intente nuevamente";
        if (response?.status === 401) {
          errorMessage = "Usuario o contraseña incorrectos";
        }
        this.setState({ error: errorMessage, _isLoading: false });
      });
  };

  redirectPath = () => {
    const locationState = this.props.location.state;
    const pathname = locationState && locationState.from;
    return pathname || "/admin";
  };

  render() {
    if (this.state.shouldRedirect) {
      return <Redirect to={this.redirectPath()} />;
    }

    const { classes } = this.props;
    return (
      <div className={classes.wrapper}>
        <div className={classes.container} ref="mainPanel">
          <GridContainer justifyContent="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card>
                <CardHeader color="success">
                  <h4 className={classes.cardTitleWhite}>Labmetrics</h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText="Usuario"
                        id="username"
                        value={this.state.form.username}
                        onChange={this.handleChange}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          name: "username",
                          autoFocus: true,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText="Contraseña"
                        id="password"
                        value={this.state.form.password}
                        onChange={this.handleChange}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          name: "password",
                          type: "password",
                          onKeyDown: this.handleKeyPress,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  {this.state.error && (
                    <FormHelperText
                      id="component-error-text"
                      className={classes.error}
                    >
                      {this.state.error}
                    </FormHelperText>
                  )}
                </CardBody>
                <CardFooter className={classes.cardFooter}>
                  {this.state._isLoading ? (
                    <CircularProgress className={classes.loading} />
                  ) : (
                    <Button color="success" onClick={this.handleSubmit}>
                      Ingresar
                    </Button>
                  )}
                </CardFooter>
              </Card>
              {!(
                (isFirefox || isEdge || isIOSChrome || isChromium !== null) &&
                typeof isChromium !== "undefined" &&
                vendorName === "Google Inc." &&
                isOpera === false &&
                isIEedge === false
              ) && (
                <SnackbarContent
                  message={
                    <span>
                      Para garantizar una mejor experiencia se recomienda
                      actualizar y usar uno de los siguientes navegadores: ►{" "}
                      {
                        <a href="https://www.google.com/intl/es-419/chrome/">
                          Google Chrome{" "}
                        </a>
                      }
                      ►{" "}
                      {
                        <a href="https://www.mozilla.org/es-ES/firefox/new/">
                          Firefox{" "}
                        </a>
                      }
                      ►{" "}
                      {
                        <a href="https://www.microsoft.com/es-co/windows/microsoft-edge">
                          Microsoft Edge{" "}
                        </a>
                      }
                    </span>
                  }
                  fullWidthMessage
                  icon={Info}
                />
              )}
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Login);
