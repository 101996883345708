import {
    usbColor
  } from "assets/jss/material-dashboard-react.jsx";
  
const customStepperStyle = {
  stepper: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-around",
    padding: 0,
    width: "100%"
  },
  
  stepper__step: {
    position: "relative",
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%"
  },
  
  stepper__step__index: {
    width: "30px",
    height: "30px",
    lineHeight: "30px",
    borderRadius: "50%",
    // background: "#dedede",
    color: "#999",
    textAlign: "center",
    marginBottom: "5px",
  },

  icon: {
    width: "100%"
  },
  
  stepper__step__label: {
    color: "#999",
    margin: 0
  },

  labelContainer: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "center"
  },

  linearProgress: {
    flex: "1 1 auto",
    position: 'absolute',
    top: 12,
    left: 'calc(-50% + 20px)',
    right: 'calc(50% + 20px)',
    backgroundColor: "#ffd8ba61"
  },

  bar: {
    backgroundColor: usbColor
  },

  currentStep: {
    // backgroundColor: usbColor+" !important",
    color: usbColor+" !important",
    fontWeight: "500"
  },

  done: {
    // backgroundColor: usbColor+" !important",
    color: usbColor+" !important",
    fontWeight: "500"
  }
};
  
  export default customStepperStyle;
  