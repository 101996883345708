import React, { Component, Fragment } from "react";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";

class UserForm extends Component {
  render() {
    const { form, onChange, institutions, roles, fieldErrors } = this.props
    return (
      <Fragment>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <CustomInput
              labelText="Usuario"
              id="username"
              value={form.username}
              onChange={onChange}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "username",
                autoComplete: "off"
              }}
              error={fieldErrors.username}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <CustomInput
              labelText="Contraseña"
              id="password"
              value={form.password}
              onChange={onChange}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "password",
                autoComplete: "off"
              }}
              error={fieldErrors.password}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <CustomInput
              labelText="Nombre"
              id="name"
              value={form.name}
              onChange={onChange}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "name"
              }}
              error={fieldErrors.name}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <CustomSelect
              labelText="Institución"
              id="institution"
              formControlProps={{
                fullWidth: true
              }}
              selectProps={{
                name: "institution"
              }}
              menuItems={institutions}
              value={form.institution}
              onChange={onChange}
              error={fieldErrors.institution}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <CustomSelect
              labelText="Rol"
              id="role"
              formControlProps={{
                fullWidth: true
              }}
              selectProps={{
                name: "role"
              }}
              menuItems={roles}
              value={form.role}
              onChange={onChange}
              error={fieldErrors.role}
            />
          </GridItem>
        </GridContainer>
      </Fragment>
    )
  }
}

export default UserForm