import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";

export default function VGFInstitutionForm(props) {

  const { form, onChange, terms, departments, cities, fieldErrors } = props;

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <CustomInput
            labelText="Nombre"
            id="name"
            value={form.name}
            onChange={onChange}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              name: "name",
              autoComplete: "off"
            }}
            error={fieldErrors.name}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <CustomInput
            labelText="Responsable"
            id="responsible"
            value={form.responsible}
            onChange={onChange}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              name: "responsible"
            }}
            error={fieldErrors.responsible}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <CustomInput
            labelText="Teléfono"
            id="phone"
            value={form.phone}                      
            onChange={onChange}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              name: "phone"
            }}
            error={fieldErrors.phone}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <CustomInput
            labelText="Correo"
            id="email"
            value={form.email}
            onChange={onChange}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              name: "email"
            }}
            error={fieldErrors.email}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <CustomSelect
            labelText="Calendario"
            id="term"
            formControlProps={{
              fullWidth: true
            }}
            selectProps={{
              name: "term"
            }}
            menuItems={terms}
            value={ terms.includes(form.term)? form.term : "" }
            onChange={onChange}
            error={fieldErrors.term}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <CustomSelect
            labelText="Departamento"
            id="department"
            formControlProps={{
              fullWidth: true
            }}
            selectProps={{
              name: "department"
            }}
            menuItems={departments}
            value={form.department}
            onChange={onChange}
            error={fieldErrors.department}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <CustomSelect
            labelText="Ciudad"
            id="city"
            formControlProps={{
              fullWidth: true
            }}
            selectProps={{
              name: "city"
            }}
            menuItems={cities}
            value={ cities.includes(form.city)? form.city : "" }
            onChange={onChange}
            error={fieldErrors.city}
          />
        </GridItem>
      </GridContainer>
    </div>
  )
}