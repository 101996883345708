import React from "react";
import cx from "classnames";

import { Switch, Route, Redirect } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
// import Header from "components/Header/Header.jsx";
import AdminNavbar from "components/Navbars/AdminNavbar";
import Sidebar from "components/Sidebar/Sidebar.js";

// assets
import logo from "assets/img/labpsicologia1.png";

import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import { client } from "../Client";

const useStyles = makeStyles(styles);

export default function Dashboard(props) {
  const { ...rest } = props;
  const routes = client.getRoutes();
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);

  // styles
  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
    });
  
  // ref for main panel div
  const mainPanel = React.createRef();

  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      // if (prop.layout === "/admin") {
      if ( prop.grantTo === undefined || client.permissions.includes(prop.grantTo) ) {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const routePath = route => route.collapse? route.views[0].path : route.path;

  if(!client.isLoggedIn()) {
    return <Redirect to='/login' />
  }

  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={routes}
        logoText={"Labmetrics"}
        logo={logo}
        image={null}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color="green"
        bgColor="black"
        miniActive={miniActive}
        {...rest}
      />
      <div className={mainPanelClasses} ref={mainPanel}>
        {/* <Header
          routes={routes}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        /> */}
        <AdminNavbar
          sidebarMinimize={sidebarMinimize.bind(this)}
          miniActive={miniActive}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        <div className={classes.content}>
          <div className={classes.container}>
            <Switch>
              {getRoutes(routes)}
              <Redirect from="/admin" to={`admin${routePath(routes[0])}`} />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
}
