import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";

const style = {
  grid: {
    margin: "0 -15px !important",
    width: "unset"
  }
};

function GridContainer(props) {
  const { classes, children, className, ...rest } = props;

  const gridClasses = classNames({
    [classes.grid]: true,
    [className]: className !== undefined
  });

  return (
    <Grid container {...rest} className={gridClasses}>
      {children}
    </Grid>
  );
}

export default withStyles(style)(GridContainer);
