import React, { Component, Fragment } from "react";
// Charts
import {Legend, ResponsiveContainer, 
  ComposedChart, Bar, XAxis, YAxis, CartesianGrid, Line, Label} from "recharts";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// Images
import colciencias from "assets/img/colciencias.png";
import usb from "assets/img/usb.jpg";
import labpsicologia from "assets/img/labpsicologia.jpg";
// Download pdf
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const translate = {
  'updating': 'Monitoreo',
  'planning': 'Planeación',
  'inhibition': 'Inhibición',
  'shifting': 'Flexibilidad',
  'tom': 'Teoría de la mente',
  'attention': 'Atención selectiva',
  'emotions': 'Reconocimiento de emociones',
  'empathy': 'Empatía',
  'judgement': 'Juicio Moral',
  'hearer com': 'Oyente',
  'deaf com': 'Sordo',
  'all com': 'Todos',
  'institution geo': 'Institución',
  'all geo': 'Nacional',
  'city geo': 'Ciudad'
}

const docPadding = {
  padding: "0px 40px"
}

const style = {
  document: {
    width: 446*2,
    height: 631*2,
    backgroundColor: "#fff"
  },
  border: {
    width: "100%",
    height: "20px",
    backgroundColor: "#3c8a3f",
    marginBottom: "25px"
  },
  header: {
    display: "flex"
  },
  headerLogo: {
    display: "flex",
    flex: 1,
    justifyContent: "center"
  },
  logo: {
    maxHeight: "100px"
  },
  headerText: {
    textAlign: "center",
    paddingTop: "20px"
  },
  title: {
    margin: "0",
    position: "relative",
    top: "-15px"
  },
  subtitle: {
    fontSize: "14px",
    position: "relative",
    top: "-20px",
    margin: "0",
    paddingTop: "5px"
  },
  info: {
    textAlign: "justify",
    fontSize: "12px",
    ...docPadding
  },
  dataContainer: {
    height: "70px"
  },
  reportData: {
    margin: 0
  },
  category: {
    margin: "25px 0 0 0",
    fontWeight: "bolder",
    position: "relative"
  },
  graph: {
    textAlign: "center"
  },
  graphTitle: {
    textAlign: "center",
    margin: "35px 5px 10px 35px"
  },
  radarChartTitle: {
    marginLeft: "0px"
  },
  graphPercentages: {
    textAlign: "center",
    margin: "0"
  },
  th: {
    padding: "10px"
  },
  footer: {
    display: "flex",
    justifyContent: "center"
  },
  footerDivider: {
    height: "1px",
    border: "0",
    borderTop: "1px solid orange",
    width: "35%"
  },
  divider1: {
    marginTop: "20px",
    marginBottom: "10px"
  },
  footerLogo: {
    maxHeight: "70px",
    margin: "0px 10px"
  },
  logoContainer: {
    position: "relative",
    bottom: "10px"
  },
  docPadding: {
    ...docPadding
  },
  table: {
    padding: ""
  },
  domain: {
    fontWeight: "bold"
  },
  subdomain: {
    fontWeight: "inherit"
  }
};

function BCSReports({ ...props }){
  const { classes, player, institution, reference, becsData, downloaded } = props

  const reportType = player? 'jugador' : 'institución'

  const now = new Date()

  const rows = [
    {
      type: 'domain',
      name: '1. Reconocimiento de emociones',
      score: becsData['Reconocimiento de emociones']?.['score'],
      tscore: becsData['Reconocimiento de emociones']?.['tscore']
    },
    {
      type: 'subdomain',
      name: '1.1 Designación',
      score: becsData['Designación']?.['score'],
      tscore: becsData['Designación']?.['tscore']
    },
    {
      type: 'subdomain',
      name: '1.2 Señalamiento',
      score: becsData['Señalamiento']?.['score'],
      tscore: becsData['Señalamiento']?.['tscore']
    },
    {
      type: 'subdomain',
      name: '1.3 Emociones en contexto',
      score: becsData['Emociones en contexto']?.['score'],
      tscore: becsData['Emociones en contexto']?.['tscore']
    },
    {
      type: 'domain',
      name: '2. Teoría de la mente',
      score: becsData['Teoría de la mente']?.['score'],
      tscore: becsData['Teoría de la mente']?.['tscore']
    },
    {
      type: 'subdomain',
      name: '2.1 Deseos',
      score: becsData['Deseos']?.['score'],
      tscore: becsData['Deseos']?.['tscore']
    },
    {
      type: 'subdomain',
      name: '2.2 Creencias',
      score: becsData['Creencias']?.['score'],
      tscore: becsData['Creencias']?.['tscore']
    },
    {
      type: 'subdomain',
      name: '2.3 Intenciones',
      score: becsData['Intenciones']?.['score'],
      tscore: becsData['Intenciones']?.['tscore']
    },
    {
      type: 'domain',
      name: '3. Empatía',
      score: becsData['Empatía']?.['score'],
      tscore: becsData['Empatía']?.['tscore']
    },
    {
      type: 'subdomain',
      name: '3.1 Valencia positiva',
      score: becsData['Empatía Valencia positiva']?.['score'],
      tscore: becsData['Empatía Valencia positiva']?.['tscore']
    },
    {
      type: 'subdomain',
      name: '3.2 Valencia negativa',
      score: becsData['Empatía Valencia negativa']?.['score'],
      tscore: becsData['Empatía Valencia negativa']?.['tscore']
    },
    {
      type: 'domain',
      name: '4. Juicio Moral',
      score: becsData['Juicio Moral']?.['score'],
      tscore: becsData['Juicio Moral']?.['tscore']
    },
    {
      type: 'subdomain',
      name: '4.1 Valencia positiva',
      score: becsData['Juicio Moral Valencia positiva']?.['score'],
      tscore: becsData['Juicio Moral Valencia positiva']?.['tscore']
    },
    {
      type: 'subdomain',
      name: '4.2 Valencia negativa',
      score: becsData['Juicio Moral Valencia negativa']?.['score'],
      tscore: becsData['Juicio Moral Valencia negativa']?.['tscore']
    },
    {
      type: 'domain',
      name: '5. Total prueba',
      score: becsData['Total prueba']?.['score'],
      tscore: becsData['Total prueba']?.['tscore']
    },
  ];

  const graph = [
    {
      subject: 'Rec. de emociones',
      sample: 50,
      me: becsData['Reconocimiento de emociones']?.['tscore']
    },
    {
      subject: 'Teoría de la mente',
      sample: 50,
      me: becsData['Teoría de la mente']?.['tscore']
    },
    {
      subject: 'Empatía',
      sample: 50,
      me: becsData['Empatía']?.['tscore']
    },
    {
      subject: 'Juicio Moral',
      sample: 50,
      me: becsData['Juicio Moral']?.['tscore']
    }
  ]

  const downloadPDF = () => {
    let pdf = new jsPDF('p','px',"a4");
    let width = pdf.internal.pageSize.getWidth(); // 446.46 px
    let height = pdf.internal.pageSize.getHeight(); // 631.4175 px

    html2canvas(document.getElementById('report-1'),{ scale: 2 }).then((canvas) => {
      let imgData1 = canvas.toDataURL('image/jpeg');

      pdf.addImage(imgData1, 'JPEG', 0, 0,width,height, null, 'NONE');

      pdf.save(`reporte-${now.getDate()}-${now.getMonth()+1}-${now.getFullYear()}.pdf`);

      downloaded() //isLoading set to false in parent component
    });
  }

  return (
    <Fragment>
    <div id="report-1" className={classes.document}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <div className={classes.border} />
          <div className={classes.header}>
            <div className={classes.headerLogo}>
              <img src={labpsicologia} alt="logo" className={classes.logo} />
            </div>
            <div className={classes.headerText}>
              <h4 className={classes.title}>Batería para evaluación de la cognición social</h4>
              <p className={classes.subtitle}>Reporte generado el {`${now.getDate()}/${now.getMonth()+1}/${now.getFullYear()}`}.</p>
            </div>
            <div className={classes.headerLogo}>
              <img src={usb} alt="usb" className={classes.logo} />
            </div>
          </div>
          <p className={classes.info}>
            Este reporte presenta los resultados en la Batería para evaluación de la Cognición Social. Esto incluye reconocimiento de emociones,
            empatía, teoría de la mente y juicio moral . El informe puede ser generado utilizando diferentes grupos de referencia que se usan
            para calcular los puntajes estandarizados (puntaje T). La interpretación y el uso de estos resultados es responsabilidad de las
            instituciones educativas. En caso de puntajes extremadamente bajos se recomienda consultar con un profesional en Psicología. Cualquier
            inquietud puede comunicarse con el Laboratorio de Psicología USB a través del correo electrónico labpsic@usbcali.edu.co, o a través
            de la línea telefónica 4882222 ext. 452.
          </p>
        </GridItem>
      </GridContainer>
      {reportType === 'jugador'? 
        <GridContainer className={classes.dataContainer}>
          <GridItem xs={6} sm={6} md={6}>
            <p className={classes.reportData+" "+classes.docPadding}><b>Nombe: </b>{player.name+" "+player.last_name}</p>
            <p className={classes.reportData+" "+classes.docPadding}><b>Género: </b>{player.genre}</p>
            <p className={classes.reportData+" "+classes.docPadding}><b>Institución: </b>{player.institution}</p>          
          </GridItem>
          <GridItem xs={6} sm={6} md={6}>
            <p className={classes.reportData+" "+classes.docPadding}><b>Escolaridad: </b>{player.scholarship}°</p>
            <p className={classes.reportData+" "+classes.docPadding}><b>Edad: </b>{player.age} años</p>
            <p className={classes.reportData+" "+classes.docPadding}><b>Grupo de referencia: </b>
              {translate[reference.group+" geo"]} - {translate[reference.communication+" com"]}
            </p>
          </GridItem>
        </GridContainer>
        :
        <GridContainer className={classes.dataContainer}>
          <GridItem xs={6} sm={6} md={6}>
            <p className={classes.reportData+" "+classes.docPadding}><b>Institución: </b>{institution.name}</p>
            <p className={classes.reportData+" "+classes.docPadding}><b>Tipo: </b>{institution.type}</p>
            <p className={classes.reportData+" "+classes.docPadding}><b>Ciudad: </b>{institution.city}</p>
          </GridItem>
          <GridItem xs={6} sm={6} md={6}>
            <p className={classes.reportData+" "+classes.docPadding}><b>Grado: </b>{reference.scholarship}°</p>
            <p className={classes.reportData+" "+classes.docPadding}><b>Grupo de referencia: </b>{translate[reference.group+" geo"]}</p>
          </GridItem>
        </GridContainer>
      }
      {/* <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <h4 className={classes.category+" "+classes.docPadding}>Funciones ejecutivas:</h4>
        </GridItem>
      </GridContainer> */}
      <br />
      <GridContainer justify="center">
        <GridItem xs={11} sm={11} md={11}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Dominio</TableCell>
                <TableCell align="right">Puntaje directo</TableCell>
                <TableCell align="right">Puntaje T</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.name}>
                  <TableCell size="small" component="th" scope="row" className={classes[row.type]}>
                    {row.name}
                  </TableCell>
                  <TableCell size="small" align="right">{row.score}</TableCell>
                  <TableCell size="small" align="right">{row.tscore}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </GridItem>
      </GridContainer>
      <GridContainer justify="center">
        <GridItem xs={10} sm={10} md={10}>
          <CustomComposedChart
            data={graph}
            height={225}
            subject={reportType}
            onRenderEnd={downloadPDF}
          />
        </GridItem>
      </GridContainer>
      <GridContainer className={classes.logoContainer}>
        <GridItem xs={12} sm={12} md={12}>
          <hr className={classes.footerDivider+" "+classes.divider1} />
          <div className={classes.footer}>
            <img src={colciencias} alt="colciencias" className={classes.footerLogo} />
          </div>
        </GridItem>
      </GridContainer>
    </div>
    </Fragment>
  );
}

class CustomComposedChart extends Component {

  render () {

    const { data, height, subject, onRenderEnd } = this.props
    
    return (
      <ResponsiveContainer width="100%" height={height? height : 260}>
        <ComposedChart data={data}
              margin={{top: 30, right: 30, left: 0, bottom: 10}}>
          <CartesianGrid strokeDasharray="3 3"/>
          <XAxis dataKey="subject" />
          <YAxis ticks={[20,30,40,50,60,70,80]} >
            <Label value={data[0].sample? null : "No disponible"} offset={127} position="right" />
          </YAxis>
          <Legend wrapperStyle={{left: "21px"}} />
          <Bar name={`Promedio ${subject}`} dataKey="me" barSize={50} fill="#4fc0d2" isAnimationActive={false}/>
          <Line name='Promedio grupo de referencia' type="monotone" dataKey="sample" stroke="#050505" dot={false} strokeWidth={2} onAnimationEnd={onRenderEnd}/>
        </ComposedChart >
      </ResponsiveContainer>
    );
  }
}

export default withStyles(style)(BCSReports);