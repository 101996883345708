import React, { useState, useEffect } from "react";
import axios from "axios";
import classNames from 'classnames';
// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
// tutorial
import Tutorial from "./Tutorial";
import img1 from "assets/img/vgf/step2.png";

import { hexToRgb, usbColor } from "assets/jss/material-dashboard-react.jsx";

// API - Client
import { client } from "Client";

const PrettoSlider = withStyles({
  root: {
    color: usbColor,
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

const style = {
  instructions: {
    textAlign: "justify",
    padding: "20px 40px",
    backgroundColor: "#eee",
    transform: "skew(-5deg)",
    fontSize: "1.1em",
    margin: "5px 30px"
  },
  sliderGroup: {
    margin: "10px 0",
    padding: "10px 30px",
    borderRadius: "5px",
    color: "#000",
    backgroundColor: "#efefef"
  },
  active: {
    backgroundColor: "#ffd8ba61"
  },
  slider_0: {
    color: "#cacaca",
  },
  slider_1: {
    color: "rgba(" + hexToRgb(usbColor) + ", 0.4)",
  },
  slider_2: {
    color: "rgba(" + hexToRgb(usbColor) + ", 0.6)",
  },
  slider_3: {
    color: "rgba(" + hexToRgb(usbColor) + ", 0.8)",
  },
  slider_4: {
    color: "rgba(" + hexToRgb(usbColor) + ", 1)",
  }
}

const tutorialSteps = [
  {
    img: img1,
    title: "",
    description: "A continuación encontrarás unas actividades relacionadas con diferentes programas de pregrado. Queremos que leas cuidadosamente y respondas de 1 a 4 qué tanto te gustan estas actividades. En este caso el número “1” indica que no te gusta y el “4” que te gusta mucho."
  }
]

const sliderMarks = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
];

// Each group has 5 statements (Database), so this must be five
const statementsByGroup = 5;

const CancelToken = axios.CancelToken;

const useStyles = makeStyles(style);

export default function Step2(props) {
  const classes = useStyles();

  const { areaId, values, handleProgress, handleChange, onSubmit } = props;

  const [isTutorial, setIsTutorial] = useState(true);

  // Array of statements arrays
  const [groupedStatements, setGroupedStatements] = useState([]);
  const [currentGroup, setCurrentGroup] = useState(0);

  const isLastGroup = currentGroup === groupedStatements.length - 1;

  useEffect(() => {
    const source = CancelToken.source();
    const axiosCancelToken = source.token;

    if(!areaId) onSubmit();

    client.getVGFGroupStatements(axiosCancelToken, areaId).then(({ group_statements }) => {
      if(group_statements.length) {
        const groups = [];

        for (let index = 0; index < group_statements.length; index += statementsByGroup) {
          let group = group_statements.slice(index, index + statementsByGroup );
          groups.push(group);
        }

        setGroupedStatements(groups);
      } else {
        onSubmit();
      }
    }).catch(error => console.log( error ))

    return () => source.cancel();
  }, [areaId, onSubmit]);

  const handleTutorialComplete = () => {
    setIsTutorial(false);
  }

  const handleButton = () => {
    if(isLastGroup) {
      onSubmit()
    } else {
      setCurrentGroup(prev => prev + 1)
      handleProgress(currentGroup, groupedStatements.length)
    }
  }

  const isButtonDisabled = () => Object.keys(values).length < (currentGroup + 1) * statementsByGroup;

  return (
    <div>
      {isTutorial
        ? <Tutorial steps={tutorialSteps} onComplete={handleTutorialComplete} />
        : 
        !groupedStatements.length? "Cargando ..." :
          <div>
            <h4 className={classes.instructions}>Recuerda <i>“ 1 ”</i> indica que no te gusta y el <i>“ 4 ”</i> que te gusta mucho.</h4>
            {groupedStatements[currentGroup].map(({ id, statement }) => (
              <div key={`div-${id}`} className={classNames({ [classes.sliderGroup]: true, [classes.active]: !!values[id] })  }>
                <Typography key={`statement-${id}`} gutterBottom>{statement}</Typography>
                <PrettoSlider
                  className={classes[`slider_${values[id]? 4 : 0}`]}
                  key={`slider-${id}`}
                  aria-labelledby={statement}
                  valueLabelDisplay="auto"
                  marks={sliderMarks}
                  min={1}
                  max={4}
                  value={values[id] ?? null}
                  onChange={(e, value) => handleChange(id, value)}
                />
              </div>
            ))}
            <GridContainer justify="center">
              <GridItem xs={12} sm={8} md={8}>
                <Button variant="contained" className={classes.button} color="usb" size="lg" fullWidth onClick={handleButton} disabled={isButtonDisabled()}>
                  {isLastGroup ? '¡Ir a la Etapa 3!' : 'Siguiente'}
                </Button>
              </GridItem>
            </GridContainer>
          </div>
      }
    </div>
  );
}