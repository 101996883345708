import React from "react";
import WheelComponent from "./raw";

const Wheel = (props) => {
  const { onFinished } = props;

  const segments = [
   '\uf001',
   '\uf1ec',
   '\uf0c3',
   '\uf02d',
   '\uf1e3',
   '\uf21e',
  ]
  const segColors = [
    '#EE4040',
    '#F0CF50',
    '#815CD1',
    '#3DA5E0',
    '#34A24F',
    '#F9AA1F',
  ]
  return (
    <WheelComponent
      segments={segments}
      segColors={segColors}
      onFinished={(winner) => onFinished(winner)}
      primaryColor='rgba(255, 255, 255, 0.5)'
      contrastColor='white'
      buttonText='Girar'
      isOnlyOnce={true}
      size={290}
      upDuration={100}
      downDuration={1000}
      fontFamily='fontawesome'
    />
  )
}

export default Wheel;