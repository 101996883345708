import React, { useCallback, useState } from "react";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// custom components
import CustomStepper from "../../components/CustomStepper/CustomStepper";
// VGF Steps
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";

// API - Client
import { client } from "Client";
import {
  toTitleCase,
  isEmail
} from "Helpers";

const steps = ['Elección de área', 'Preguntas específicas de área', 'Resultados'];

export default function Form(props) {

  const { testCode, onSubmit } = props;

  // Stepper
  const [activeStep, setActiveStep] = useState(0);
  // Step progress
  const [progress, setProgress] = useState(0);
  // Whatever props to share between steps
  const [toNextStep, setToNextStep] = useState(null);
  // Student
  const [student, setStudent] = useState({
    id: null,
    name: '',
    lastName: '',
    email: '',
    email2: '',
    phone: '',
    birthdate: '',
    preferredAreaId: '',
    preferredSocialM: '',
    wantNext: '',
    nextStep: '',
  });
  const [areaChoices, setAreaChoices] = useState({});
  const [groupScores, setGroupScores] = useState({});

  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({});

  /**
   * Handle events
   */

  const handleRadioChange = (statement_id, option_id) => {
    setAreaChoices(prev => ({
      ...prev,
      [statement_id]: Number(option_id)
    }));
  }

  const handleSliderChange = (statement_id, score) => {
    setGroupScores(prev => ({
      ...prev,
      [statement_id]: Number(score)
    }));
  }

  const handleStudentChange = (name, value) => {
    setStudent(prev => ({
      ...prev,
      [name]: value
    }));
  }

  const handleProgress = (current, total) => {
    const progress = (current+1) * 100 / total;
    setProgress(progress);
  }

  const handleSubmit = useCallback(propToNextStep => {
    if(propToNextStep) setToNextStep(propToNextStep);
    setActiveStep(prev => prev + 1)
    setProgress(0)
  }, []);

  const validate = () => {
    const errors = {};
    if (!student.id) errors.id = 'Identificación requerida';
    if (!student.name) errors.name = 'Nombre requerido';
    if (!student.lastName) errors.lastName = 'Apellido requerido';
    if (!student.email) errors.email = 'Correo requerido';
    if (student.email && !isEmail(student.email)) errors.email = 'Ingrese un correo válido';
    if (student.email != student.email2) errors.email2 = 'El correo no coincide';
    if (!student.preferredAreaId) errors.preferredAreaId = 'Área requerida';
    if (!student.preferredSocialM) errors.preferredSocialM = 'Campo requerido';
    if(!student.wantNext) errors.wantNext = 'Campo requerido';
    if(student.wantNext === 'Si' && !student.nextStep) errors.nextStep = 'Campo Requerido';
    return errors;
  }

  const getFormErrors = messages => {
    const errors = {};
    for (const key in messages) {
      if (messages.hasOwnProperty(key)) {
        errors[key] = messages[key][0];
      }
    }
    return errors;
  }

  const submitForm = () => {
    const errors = validate();
    if(Object.keys(errors).length) {
      setErrors(errors);
      return;
    }

    setLoading(true);

    const data = {
      ...student,
      id: Number(student['id']),
      name: toTitleCase(student['name']).trim(),
      lastName: toTitleCase(student['lastName']).trim(),
      email: student['email'].trim().toLowerCase(),
      phone: student['phone'].trim(),
      testCode: testCode,
      nextStep: student.wantNext === 'Si'? student.nextStep : null,
      areaChoices: Object.values(areaChoices),
      groupScores: groupScores
    }

    client.submitVGF(data).then(data => {

      if(data.ok) onSubmit(data.results);

    }).catch(({ response }) => {
      // It works for many but not all of the fields (When they include underscore (_) on API, it doesn't work: check Client.js)
      // This can be fixed just by renaming state fields to match with those on Client.js (API)
      // This is not an issue now, so I prefer to keep current fields names.
      const fieldErrors = getFormErrors(response.data.message);
      setErrors(fieldErrors);
      setLoading(false);
    });
  }
  
  return (
    <div>
      <CustomStepper steps={steps} current={activeStep} progress={progress} />
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={9}>
          {activeStep === 0 &&
            <Step1 values={areaChoices} handleChange={handleRadioChange} handleProgress={handleProgress} onSubmit={handleSubmit} />
          }
          {activeStep === 1 &&
            <Step2 areaId={toNextStep} values={groupScores} handleChange={handleSliderChange} handleProgress={handleProgress} onSubmit={handleSubmit} />
          }
          {activeStep === 2 &&
            <Step3 values={student} errors={errors} onChange={handleStudentChange} onSubmit={submitForm} loading={loading} />
          }
        </GridItem>
      </GridContainer>
    </div>
  );
}
