import React, { Component } from "react";
import axios from "axios";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import UserForm from "../../components/UserForm/UserForm";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
// API - Client
import { client } from "../../Client";

const CancelToken = axios.CancelToken;
let source;
let axiosCancelToken;

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

class AddUser extends Component {
  state ={
    form: {
      username: "",
      password: "",
      name: "",
      institution: "",
      role: ""
    },
    fieldErrors: {},
    institutions: [],
    roles: [],
    _savedNotification: false,
    _lastAdded: ""
  }

  componentDidMount() {
    source = CancelToken.source();
    axiosCancelToken = source.token;
    
    this.getInstitutions()
    this.getRoles()
  }

  componentWillUnmount() {
    source.cancel();
  }

  getInstitutions = () => {
    client.getInstitutions(axiosCancelToken).then(institutions => {
      this.setState({ institutions })
    }).catch(error => {})
  }

  getRoles = () => {
    client.getRoles(axiosCancelToken).then((data) => {
      this.setState({ roles: data })
    }).catch(error => {})
  }

  handleChange = (evt) => {
    const name = evt.target.name;
    const value = evt.target.value;

    this.setState(prevState => ({
      form: {
        ...prevState.form,
        [name]: value
      }
    }));
  }

  validate = (form) => {
    const errors = {};
    if (!form.username) errors.username = 'Usuario requerido';
    if (!form.password) errors.password = 'Contraseña requerida';
    if (!form.name) errors.name = 'Nombre requerido';
    if (!form.institution) errors.institution = 'Institución requerida';
    if (!form.role) errors.role = 'Rol requerido';
    return errors;
  };

  getFormErrors = (messages) => {
    const errors = {}
    for (const key in messages) {
      if (messages.hasOwnProperty(key)) {
        errors[key] = messages[key][0]        
      }
    }
    return errors
  }

  handleSubmit = () => {
    const form = this.state.form;
    const fieldErrors = this.validate(form)
    this.setState({ fieldErrors })
    if( Object.keys(fieldErrors).length ) return;

    client.AddUser(this.state.form).then(({ data }) => {
      if ( data.ok ) {
        this.setState(prevState => ({
          form: {
            username: "",
            password: "",
            name: "",
            institution: "",
            role: ""
          },
          _lastAdded: prevState.form.username
        }))

        this.showNotification();
      }
    }).catch(({ response }) => {
      const fieldErrors = this.getFormErrors(response.data.message)
      this.setState({ fieldErrors })
    })
  }

  showNotification = () => {
    this.setState({ _savedNotification: true })

    setTimeout(() => {
      this.setState({ _savedNotification: false })
    }, 3000)
  }

  render () {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success">
                <h4 className={classes.cardTitleWhite}>Nuevo Administrador</h4>
                <p className={classes.cardCategoryWhite}>Complete la información y guarde para añadir un nuevo administrador</p>
              </CardHeader>
              <CardBody>
                <UserForm form={this.state.form} onChange={this.handleChange} 
                  institutions={this.state.institutions} roles={this.state.roles} fieldErrors={this.state.fieldErrors} />
              </CardBody>
              <CardFooter>
                <Button color="success" onClick={this.handleSubmit} disabled={this.state.form.username === ""} >Guardar</Button>
                <Snackbar
                  place="br" // Bottom Right
                  color="success"
                  icon={CheckCircleOutline}
                  message={`El usuario '${this.state._lastAdded}' fue agregado correctamente!`}
                  open={this.state._savedNotification}
                  closeNotification={() => this.setState({ _savedNotification: false })}
                  close
                />
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(styles)(AddUser);
