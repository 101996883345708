import React, { Component, Fragment } from "react";

// Charts
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Line,
  Legend,
  LineChart,
  Cell,
  BarChart,
  Bar,
} from "recharts";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
// Images
import logo from "assets/img/vgf.png";
import usb from "assets/img/usb.png";
import labpsicologia from "assets/img/labpsicologia.jpg";

// Download pdf
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const docPadding = {
  padding: "0px 40px !important",
};

const style = {
  document: {
    width: 446 * 2,
    height: 631 * 2,
    backgroundColor: "#fff",
  },
  border: {
    width: "100%",
    height: "25px",
    backgroundColor: "#fc9209",
    marginBottom: "25px",
  },
  logo: {
    maxHeight: "115px",
    margin: "0px 10px 10px 10px",
    ...docPadding,
  },
  rightLogo: {
    top: "50px",
    right: 0,
  },
  headerText: {
    textAlign: "center",
    paddingTop: "20px",
  },
  title: {
    margin: "0",
    position: "relative",
    top: "-15px",
  },
  subtitle: {
    fontSize: "14px",
    position: "relative",
    top: "-20px",
    margin: "0",
    paddingTop: "5px",
  },
  info: {
    textAlign: "justify",
    fontSize: "16px",
    ...docPadding,
  },
  reportData: {
    margin: 0,
  },
  category: {
    margin: "25px 0 0 0",
    fontWeight: "bolder",
    position: "relative",
  },
  graph: {
    textAlign: "center",
  },
  graphTitle: {
    margin: "15px 5px 10px 35px",
  },
  radarChartTitle: {
    marginLeft: "0px",
  },
  graphPercentages: {
    textAlign: "center",
    margin: "0",
  },
  th: {
    padding: "10px",
  },
  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  footerDivider: {
    height: "1px",
    border: "0",
    borderTop: "1px solid orange",
    width: "40%",
  },
  divider1: {
    marginTop: "20px",
  },
  docPadding: {
    ...docPadding,
  },
  legend: {
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
    marginTop: "10px",
    flexWrap: "wrap",
    justifyContent: "center",
    ...docPadding,
  },
  footerLogo: {
    maxWidth: "115px",
  },
  legendItem: {
    display: "flex",
    alignItems: "center",
    marginRight: "20px",
    marginBottom: "10px",
  },
  legendCircle: {
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    marginRight: "10px",
  },
  table: {},
  minitable: {},
  glosary: {
    fontSize: "16px",
    textAlign: "justify",
  },
  section: {
    ...docPadding,
    margin: "0px 0px 20px 0px",
  },
};

const AREAS = [
  "Ingeniería",
  "Ciencias Sociales",
  "Ciencias Jurídicas y políticas",
  "Ciencias Básicas",
  "Ciencias de la Salud",
  "Ciencias Administrativas",
  "Artes",
];

const convertDataLine = ({ preferredAreas, firstAreas, match }) => {
  return AREAS.map((area) => {
    const preferredAreaItem = preferredAreas.find(
      (item) => item.name === area
    ) || { freq: 0 };
    const firstAreaItem = firstAreas.find((item) => item.name === area) || {
      freq: 0,
    };
    const matchItem = match.find((item) => item.name === area) || { match: 0 };

    return {
      name: area,
      Elegidas: preferredAreaItem.freq,
      Obtenidas: firstAreaItem.freq,
      Coincide: matchItem.match,
    };
  });
};

function convertDataBarPercentage({ match, preferredAreas }) {
  return AREAS.map((area) => {
    let obj = match.find((item) => item.name === area);

    if (obj) {
      const total = preferredAreas.find((item) => item.name === area)?.freq;

      return {
        name: area,
        value: total
          ? Math.floor((Number(obj.match) / Number(total)) * 100)
          : 0,
      };
    } else {
      return null;
    }
  }).filter((item) => item !== null);
}

function convertDataBar(inputData) {
  return inputData.map((item) => ({
    name: item.name,
    value: item.freq,
  }));
}

function Reports({ ...props }) {
  const { classes, info, data, downloaded } = props;
  const {
    preferredAreas,
    firstAreas,
    match,
    preferredSocialMedia,
    preferredNextSteps,
    firstAreaName,
    secondAreaName,
    firstPrograms,
    secondPrograms,
  } = data;

  const now = new Date();

  const downloadPDF = () => {
    let pdf = new jsPDF("p", "px", "a4");
    let width = pdf.internal.pageSize.getWidth(); // 446.46 px
    let height = pdf.internal.pageSize.getHeight(); // 631.4175 px
    html2canvas(document.getElementById("report-1"), { scale: 2 }).then(
      (canvas) => {
        let imgData1 = canvas.toDataURL("image/jpeg");
        pdf.addImage(imgData1, "JPEG", 0, 0, width, height, null, "NONE");
        html2canvas(document.getElementById("report-2"), { scale: 2 }).then(
          (canvas) => {
            let imgData2 = canvas.toDataURL("image/jpeg");
            pdf.addPage();
            pdf.addImage(imgData2, "JPEG", 0, 0, width, height, null, "NONE");
            html2canvas(document.getElementById("report-3"), { scale: 2 }).then(
              (canvas) => {
                let imgData3 = canvas.toDataURL("image/jpeg");
                pdf.addPage();
                pdf.addImage(
                  imgData3,
                  "JPEG",
                  0,
                  0,
                  width,
                  height,
                  null,
                  "NONE"
                );
                pdf.save(
                  `reporte-${now.getDate()}-${
                    now.getMonth() + 1
                  }-${now.getFullYear()}.pdf`
                );
              }
            );
            downloaded(); //isLoading set to false in parent component
          }
        );
      }
    );
  };

  return (
    <Fragment>
      <div id="report-1" className={classes.document}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <div className={classes.border} />
            <div className={classes.header}>
              <div className={classes.headerText}>
                <img src={logo} alt="logo" className={classes.logo} />
                <h3 className={classes.title}>Brújula</h3>
                <h5 className={classes.title}>
                  Prueba de orientación vocacional
                </h5>
                <small className={classes.subtitle}>
                  Reporte institutional
                </small>
                <p className={classes.subtitle}>
                  Generado el{" "}
                  {`${now.getDate()}/${
                    now.getMonth() + 1
                  }/${now.getFullYear()}`}
                  .
                </p>
              </div>
            </div>
            <p className={classes.info}>
              Este informe presenta las tendencias en la elección vocacional de
              los estudiantes de la institución educativa {info?.institution}
            </p>
            <br />
          </GridItem>
          <GridItem xs={12} sm={12} md={12} className={classes.section}>
            <h4 className={classes.graphTitle}>Glosario:</h4>
            <ul className={classes.glosary}>
              <li>
                <b>Elegida:</b> Es el área en la cual el estudiante considera
                que puede tener intereses vocacionales, de acuerdo a lo que
                conoce del área o de las profesiones que la integran.
                <br />
              </li>
              <li>
                <b>Obtenida:</b> Es el área de interés vocacional que selecciona
                la prueba, de acuerdo a los resultados que se encuentran en la
                misma. La prueba realiza un análisis del nivel de interés que
                tiene el estudiante de acuerdo al porcentaje de selección de las
                respuestas de cada una de las áreas presentadas, para poder
                establecer un perfil vocacional.
                <br />
              </li>
              <li>
                <b>Coincidencia:</b> Es la relación entre el área elegida por el
                estudiante y el resultado que arroja la prueba. Estos resultados
                no necesariamente deben coincidir, muchas veces los estudiantes
                no tienen claras sus habilidades por lo que este resultado puede
                ser distinto, sin embargo, en el caso de aquellos estudiantes a
                quienes les coincida su interés con el resultado de su prueba,
                puede ser un indicador de que esta persona tiene muy claro su
                elección vocacional.
                <br />
              </li>
              <li>
                <b>Participantes sin inclinación detectada:</b> La prueba tiene
                un nivel mínimo de interés por cada campo, para así poder
                seleccionar el campo con el cual se relacionan sus intereses
                vocacionales. Si el estudiante no obtiene el nivel mínimo en
                ninguno de los campos, aparecerá que no tiene una inclinación
                detectada, lo cual puede deberse a que ha contestado la prueba
                al azar o también puede tener muchas dudas con respecto a su
                elección, por lo que no hay un interés marcado frente a un campo
                <br />
              </li>
            </ul>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} className={classes.section}>
            <h4 className={classes.graphTitle}>
              Áreas de mayor interés vocacional:
            </h4>
            <ul className={classes.glosary}>
              <li>
                <b>
                  {firstAreaName ??
                    "No se ha detectado una inclinación vocacional hacia ningún área"}
                  :{" "}
                </b>
                {firstAreaName && firstPrograms.length > 0
                  ? firstPrograms.map(
                      (program, index) =>
                        program.name +
                        (index === firstPrograms.length - 1 ? "." : ", ")
                    )
                  : "No se ha detectado una inclinación vocacional hacia ningún programa en específico."}
                <br />
              </li>
              <li>
                <b>
                  {secondAreaName ??
                    "No se ha detectado una inclinación vocacional hacia ningún otra área"}
                  :{" "}
                </b>
                {secondAreaName && secondPrograms.length > 0
                  ? secondPrograms.map(
                      (program, index) =>
                        program.name +
                        (index === secondPrograms.length - 1 ? "." : ", ")
                    )
                  : "No se ha detectado una inclinación vocacional hacia ningún programa en específico."}
                <br />
              </li>
            </ul>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <hr className={classes.footerDivider + " " + classes.divider1} />
            <div className={classes.footer}>
              <img src={usb} alt="usb" className={classes.footerLogo} />
              <img
                src={labpsicologia}
                alt="labpsicologia"
                className={classes.footerLogo}
              />
            </div>
          </GridItem>
        </GridContainer>
      </div>
      <hr />
      <div id="report-2" className={classes.document}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <div className={classes.border} />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} className={classes.section}>
            <h4 className={classes.graphTitle}>Datos demográficos:</h4>
            <Table className={classes.table} aria-label="simple table">
              <TableBody>
                <TableRow>
                  <TableCell>Grados:</TableCell>
                  <TableCell align="right">{info?.scholarship}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Periodo (s) académico (s):</TableCell>
                  <TableCell align="right">{info?.date}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <br />
            <Table className={classes.minitable} aria-label="simple table">
              <TableBody>
                <TableRow>
                  <TableCell align="center">N° de participantes</TableCell>
                  <TableCell align="center">Edad mínima</TableCell>
                  <TableCell align="center">Edad promedio</TableCell>
                  <TableCell align="center">Edad máxima</TableCell>
                  <TableCell align="center">
                    Usuarios sin <br />
                    inclinación detectada
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">{info?.obtained_tests}</TableCell>
                  <TableCell align="center">{info?.min_age}</TableCell>
                  <TableCell align="center">{info?.avg_age}</TableCell>
                  <TableCell align="center">{info?.max_age}</TableCell>
                  <TableCell align="center">
                    {Math.round((info?.no_area * 100) / info?.obtained_tests)}%
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} className={classes.section}>
            <h4 className={classes.graphTitle}>Gráfica de resultados:</h4>
            <CustomLineChart
              data={convertDataLine({ preferredAreas, firstAreas, match })}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} className={classes.section}>
            <h4 className={classes.graphTitle}>Tabla de resultados:</h4>
            <Table className={classes.minitable} aria-label="simple table">
              <TableBody>
                <TableRow>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center">Ingeniería</TableCell>
                  <TableCell align="center">C. Sociales</TableCell>
                  <TableCell align="center">C. Jurídicas y políticas</TableCell>
                  <TableCell align="center">C. Básicas</TableCell>
                  <TableCell align="center">C. Salud</TableCell>
                  <TableCell align="center">C. Administrativas</TableCell>
                  <TableCell align="center">Artes</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Elegidas</TableCell>
                  <TableCell align="center">
                    {preferredAreas.find((item) => item.name === "Ingeniería")
                      ?.freq || 0}
                  </TableCell>
                  <TableCell align="center">
                    {preferredAreas.find(
                      (item) => item.name === "Ciencias Sociales"
                    )?.freq || 0}
                  </TableCell>
                  <TableCell align="center">
                    {preferredAreas.find(
                      (item) => item.name === "Ciencias Jurídicas y políticas"
                    )?.freq || 0}
                  </TableCell>
                  <TableCell align="center">
                    {preferredAreas.find(
                      (item) => item.name === "Ciencias Básicas"
                    )?.freq || 0}
                  </TableCell>
                  <TableCell align="center">
                    {preferredAreas.find(
                      (item) => item.name === "Ciencias de la Salud"
                    )?.freq || 0}
                  </TableCell>
                  <TableCell align="center">
                    {preferredAreas.find(
                      (item) => item.name === "Ciencias Administrativas"
                    )?.freq || 0}
                  </TableCell>
                  <TableCell align="center">
                    {preferredAreas.find((item) => item.name === "Artes")
                      ?.freq || 0}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Obtenidas</TableCell>
                  <TableCell align="center">
                    {firstAreas.find((item) => item.name === "Ingeniería")
                      ?.freq || 0}
                  </TableCell>
                  <TableCell align="center">
                    {firstAreas.find(
                      (item) => item.name === "Ciencias Sociales"
                    )?.freq || 0}
                  </TableCell>
                  <TableCell align="center">
                    {firstAreas.find(
                      (item) => item.name === "Ciencias Jurídicas y políticas"
                    )?.freq || 0}
                  </TableCell>
                  <TableCell align="center">
                    {firstAreas.find((item) => item.name === "Ciencias Básicas")
                      ?.freq || 0}
                  </TableCell>
                  <TableCell align="center">
                    {firstAreas.find(
                      (item) => item.name === "Ciencias de la Salud"
                    )?.freq || 0}
                  </TableCell>
                  <TableCell align="center">
                    {firstAreas.find(
                      (item) => item.name === "Ciencias Administrativas"
                    )?.freq || 0}
                  </TableCell>
                  <TableCell align="center">
                    {firstAreas.find((item) => item.name === "Artes")?.freq ||
                      0}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">Coincide</TableCell>
                  <TableCell align="center">
                    {match.find((item) => item.name === "Ingeniería")?.match ||
                      0}
                  </TableCell>
                  <TableCell align="center">
                    {match.find((item) => item.name === "Ciencias Sociales")
                      ?.match || 0}
                  </TableCell>
                  <TableCell align="center">
                    {match.find(
                      (item) => item.name === "Ciencias Jurídicas y políticas"
                    )?.match || 0}
                  </TableCell>
                  <TableCell align="center">
                    {match.find((item) => item.name === "Ciencias Básicas")
                      ?.match || 0}
                  </TableCell>
                  <TableCell align="center">
                    {match.find((item) => item.name === "Ciencias de la Salud")
                      ?.match || 0}
                  </TableCell>
                  <TableCell align="center">
                    {match.find(
                      (item) => item.name === "Ciencias Administrativas"
                    )?.match || 0}
                  </TableCell>
                  <TableCell align="center">
                    {match.find((item) => item.name === "Artes")?.match || 0}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </GridItem>
        </GridContainer>
      </div>
      <hr />
      <div id="report-3" className={classes.document}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <div className={classes.border} />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} className={classes.section}>
            <h4 className={classes.graphTitle}>
              Porcentaje de estudiantes en quienes coincidió el área elegida con
              la obtenida en la prueba:
            </h4>
            <CustomBarPercentage
              data={convertDataBarPercentage({ match, preferredAreas })}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} className={classes.section}>
            <h4 className={classes.category + " " + classes.docPadding}>
              ¿Por cuál canal prefieres seguir a tus influencers favoritos?
            </h4>
            <CustomBar data={convertDataBar(preferredSocialMedia)} />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} className={classes.section}>
            <h4 className={classes.category + " " + classes.docPadding}>
              Si al terminar el bachillerato tienes planeado seguir estudiando,
              ¿Qué tienes planeado estudiar?
            </h4>
            <CustomBar
              data={convertDataBar(preferredNextSteps)}
              onRenderEnd={downloadPDF}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <hr className={classes.footerDivider + " " + classes.divider1} />
            <div className={classes.footer}>
              <img src={usb} alt="usb" className={classes.footerLogo} />
              <img
                src={labpsicologia}
                alt="labpsicologia"
                className={classes.footerLogo}
              />
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </Fragment>
  );
}

class CustomLineChart extends Component {
  render() {
    const { data, height } = this.props;

    return (
      <ResponsiveContainer width="100%" height={height ? height : 360}>
        <LineChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 10,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            height={80}
            tick={<CustomizedAxisTick />}
            includeHidden
            interval={0}
            padding={{ left: 10 }}
            label={{
              value: "Áreas",
              position: "insideBottomRight",
              offset: 0,
            }}
          />
          <YAxis
            allowDecimals={false}
            tickCount={10}
            type="number"
            label={{
              value: "N° de estudiantes",
              angle: -90,
              position: "left",
            }}
          />
          <Legend />
          <Line
            type="monotone"
            dataKey="Elegidas"
            stroke="#0088FE"
            strokeWidth={3}
            strokeDasharray="2 2 2"
            isAnimationActive={false}
          />
          <Line
            type="monotone"
            dataKey="Obtenidas"
            stroke="#00C49F"
            strokeWidth={4}
            label={<CustomizedLabel />}
            isAnimationActive={false}
          />
          <Line
            type="monotone"
            dataKey="Coincide"
            stroke="#ff5050"
            strokeWidth={2}
            strokeDasharray="8 8 8"
            isAnimationActive={false}
          />
        </LineChart>
      </ResponsiveContainer>
    );
  }
}

class CustomBarPercentage extends Component {
  render() {
    const { data, height, onRenderEnd } = this.props;

    return (
      <ResponsiveContainer width="100%" height={height ? height : 230}>
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 15,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            tick={<CustomizedAxisTick lineBreak={true} />}
            includeHidden
            interval={0}
            label={{
              value: "Áreas",
              position: "insideBottomRight",
              offset: -10,
            }}
          />
          <YAxis
            allowDecimals={false}
            domain={[0, 100]}
            type="number"
            label={{
              value: "Coincidencia (%)",
              angle: -90,
              position: "left",
            }}
          />
          <Bar
            dataKey="value"
            isAnimationActive={false}
            label={{ position: "top" }}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill="#ff5050" />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    );
  }
}

class CustomBar extends Component {
  render() {
    const { data, height, onRenderEnd } = this.props;

    return (
      <ResponsiveContainer width="100%" height={height ? height : 230}>
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 15,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            tick={<CustomizedAxisTick />}
            includeHidden
            interval={0}
            label={{
              value: "Opciones",
              position: "insideBottomRight",
              offset: -10,
            }}
          />
          <YAxis
            allowDecimals={false}
            label={{
              value: "N° de estudiantes",
              angle: -90,
              position: "left",
            }}
          />
          <Bar
            dataKey="value"
            onAnimationEnd={onRenderEnd}
            label={{ position: "top" }}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill="#ff5050" />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    );
  }
}

class CustomizedLabel extends Component {
  render() {
    const { x, y, stroke, value } = this.props;

    return (
      <text
        x={x}
        y={y - 5}
        dy={-4}
        fill={stroke}
        fontSize={16}
        fontWeight={700}
        textAnchor="middle"
      >
        {value}
      </text>
    );
  }
}

class CustomizedAxisTick extends Component {
  render() {
    const { x, y, payload, lineBreak } = this.props;

    const short = {
      Ingeniería: "Ingeniería",
      "Ciencias Sociales": "C. Sociales",
      "Ciencias Jurídicas y políticas": lineBreak
        ? ["C. Jurídicas", "y Políticas"]
        : "C. Jurídicas y políticas",
      "Ciencias Básicas": "C. Básicas",
      "Ciencias de la Salud": "C. Salud",
      "Ciencias Administrativas": "C. Administrativas",
      Artes: "Artes",
    };

    const hei = lineBreak ? y + 10 : y;

    return (
      <g transform={`translate(${x},${hei})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="middle"
          fontSize={14}
          fill="#666"
          transform="rotate(0)"
        >
          {Array.isArray(short[payload.value])
            ? short[payload.value].map((line, index) => (
                <tspan x={0} dy={index * 16} key={index}>
                  {line}
                </tspan>
              ))
            : short[payload.value] || payload.value}
        </text>
      </g>
    );
  }
}

export default withStyles(style)(Reports);
