import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";

const style = {
  title: {
    textAlign: "justify",
    fontWeight: "900"
  },
  imgContainer: {
    textAlign: "center"
  },
  img: {
    maxWidth: "108%",
    maxHeight: "400px"
  }
}

const useStyles = makeStyles(style);

export default function Tutorial(props) {
  const classes = useStyles();

  const { steps, onComplete } = props;

  const [activeStep, setActiveStep] = useState(0);

  const isLastStep = activeStep === steps.length - 1;

  const handleNext = () => {
    isLastStep
    ? onComplete()
    : setActiveStep(prev => prev + 1)
  };

  // const handleBack = () => {
  //   setActiveStep(prev => prev - 1)
  // };

  return (
    <div>
      <div className={classes.imgContainer}>
        <img src={steps[activeStep]?.img} className={classes.img} alt=""/>
      </div>
      <h4 className={classes.title}>{steps[activeStep]?.title}</h4>
      <p>{steps[activeStep]?.description}</p>
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={8} md={8}>
          <Button variant="contained" className={classes.button} color="usb" size="lg" fullWidth onClick={handleNext}>
            ¡Entendido!
          </Button>
        </GridItem>
      </GridContainer>
      {/* <MobileStepper
      variant="dots"
      steps={steps.length}
      position="static"
      activeStep={activeStep}
      className={classes.root}
      nextButton={
          <Button color={isLastStep? "usb" : "warning"} onClick={handleNext}>
          {isLastStep? "Iniciar" : "Siguiente"}
          <KeyboardArrowRight />
          </Button>
      }
      backButton={
          <Button onClick={handleBack} disabled={activeStep === 0}>
          <KeyboardArrowLeft />
          Atrás
          </Button>
      }
      /> */}
    </div>
  );
}