import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import Index from "views/Index/Index.js";
import VocationalGuidanceForm from "views/VocationalGuidanceForm/VocationalGuidanceForm.js";
import Login from "views/Login/Login.js";
import AdminLayout from "layouts/Admin.js";

import "assets/css/material-dashboard-react.css?v=1.5.0";

const hist = createBrowserHistory();

// TODO: Introduce code splitting to reduce bundle size. Check this out: https://reactjs.org/docs/code-splitting.html#route-based-code-splitting

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/home" component={Index} />
      <Route path="/prueba-orientacion-vocacional" component={VocationalGuidanceForm} />
      <Route path="/login" component={Login} />
      <Route path="/admin" component={AdminLayout} />
      <Redirect from="/" to="/home" />
    </Switch>
  </Router>,
  document.getElementById("root")
);
