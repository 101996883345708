import React, { Fragment } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import TablePagination from '@material-ui/core/TablePagination';
import CircularProgress from '@material-ui/core/CircularProgress';
// @material-ui/icons
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Search from "@material-ui/icons/Search";
// core components
import tableStyle from "assets/jss/material-dashboard-react/components/tableStyle.jsx";

function CustomTable({ ...props }) {
  const { classes, tableHead, tableData, tableHeaderColor, onRemove, onEdit, customActions, search, paginationProps, loading, hideID = false } = props;

  const getActions = () => {
    const actions = [];

    if(onEdit) {
      actions.push({
        tooltip: 'Editar',
        icon: Edit,
        iconStyle: classes.edit,
        onClick: onEdit
      })
    }

    if(onRemove) {
      actions.push({
        tooltip: 'Eliminar',
        icon: Close,
        iconStyle: classes.close,
        onClick: onRemove
      })
    }

    return [
      ...customActions,
      ...actions
    ]
  }

  /**
   * Given an Array, Object or String. Return property to include in table cell
   * @param {*} prop 
   */
  const getProperty = (prop) => {
    switch (true) {
      case (prop instanceof Array):
        return prop.map((prop, key) => (
          <Fragment key={key} >
            {prop} 
            <br />
          </Fragment>
        ))
      
      case (prop.name && prop instanceof Object):
        return prop.name

      default:
        return prop
    }
  }

  /**
   * Return table rows (Array of cells) by object keys
   * @param {Object} object
   * @returns {Array} row
   */
  const tableCells = (object) => {
    let row = []
  
    for (const key in object) {
      if(hideID && key === "id") continue;
      if (object.hasOwnProperty(key)) {

        let cell = (
          <TableCell className={classes.tableCell} key={key}>
            {getProperty(object[key])}
          </TableCell>
        )

        row.push(cell)
      }
    }
  
    return row
  }

  return (
    <div className={classes.tableResponsive+" "+classes.marginZero}>
      {!!search &&
        <div className={classes.searchWrapper}>
          <CustomInput
            formControlProps={{
              className: classes.marginZero
            }}
            inputProps={{
              placeholder: search.placeholder,
              inputProps: {
                name: "search",
                "aria-label": search.placeholder
              }
            }}
            value={search.value}
            onChange={search.onChange}
          />
          <Button className={classes.searchIcon} color="warning" aria-label="edit" justIcon onClick={search.onClick}>
            <Search />
          </Button>
        </div>
      }
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow>
              {tableHead.map((prop, key) => {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {!tableData.length &&
            <TableRow>
              <TableCell className={classes.tableCell + " " + classes.noDataAvailable} colSpan={tableHead.length}>
                {loading? <CircularProgress className={classes.loading} /> : "Aún no hay registros disponibles"}
              </TableCell>
            </TableRow>
          }
          {tableData.map((row, key) => {
            return (
              <TableRow key={key}>
                {tableCells(row)}
                <TableCell className={classes.tableActions}>
                  {getActions().map(action => (
                    (row.id === 1 && action.tooltip === 'Eliminar')? null :
                    <Tooltip
                      id={`tooltip-top-${action.tooltip}`}
                      title={action.tooltip}
                      placement="top"
                      classes={{ tooltip: classes.tooltip, popper: classes.popper}}
                      key={`${row.id}-${action.tooltip}`}
                    >
                      <IconButton
                        aria-label={action.tooltip}
                        className={classes.tableActionButton}
                        onClick={() => action.onClick(row)}
                      >
                        <action.icon
                          className={
                            `${classes.tableActionButtonIcon} ${action.iconStyle ?? ''}`
                          }
                        />
                      </IconButton>
                    </Tooltip>
                  ))}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {!!paginationProps &&
        <TablePagination
          rowsPerPageOptions={[10, 15, 20, 25]}
          component="div"
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          labelRowsPerPage="Registros por página"
          labelDisplayedRows={	({ from, to, count }) => `${from}-${to} de ${count}`}
          {...paginationProps}
        />
      }
    </div>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray",
  loading: false,
  customActions: []
};

CustomTable.propTypes = {
  classes: PropTypes.object.isRequired,
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.object),
  customActions: PropTypes.arrayOf(PropTypes.object)
};

export default withStyles(tableStyle)(CustomTable);
