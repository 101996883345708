import React, { Component, Fragment } from "react";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomMultipleSelect from "../../components/CustomMultipleSelect/CustomMultipleSelect";
import CustomDatepicker from "components/CustomDatepicker/CustomDatepicker.jsx";

class PlayerForm extends Component {
  render() {
    const { form, onChange, scholarships, genres,
      communications, institutions, auditoryLossMoments, auditoryLossGrades, diagnoses, fieldErrors } = this.props
    return (
      <Fragment>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <CustomInput
              labelText="Usuario"
              id="username"
              value={form.username}
              onChange={onChange}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "username",
                autoComplete: "off"
              }}
              error={fieldErrors.username}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <CustomInput
              labelText="Contraseña"
              id="password"
              value={form.password}
              onChange={onChange}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "password",
                autoComplete: "off"
              }}
              error={fieldErrors.password}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <CustomInput
              labelText="Nombre"
              id="name"
              value={form.name}
              onChange={onChange}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "name"
              }}
              error={fieldErrors.name}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <CustomInput
              labelText="Apellidos"
              id="lastName"
              value={form.lastName}
              onChange={onChange}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "lastName"
              }}
              error={fieldErrors.lastName}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <CustomDatepicker
              labelText="Fecha de Nacimiento"
              id="birthdate"
              value={form.birthdate}
              onChange={onChange}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "birthdate"
              }}
              error={fieldErrors.birthdate}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <CustomSelect
              labelText="Género"
              id="genre"
              formControlProps={{
                fullWidth: true
              }}
              selectProps={{
                name: "genre"
              }}
              menuItems={genres}
              value={form.genre}
              onChange={onChange}
              error={fieldErrors.genre}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <CustomSelect
              labelText="Institución"
              id="institution"
              formControlProps={{
                fullWidth: true
              }}
              selectProps={{
                name: "institution"
              }}
              menuItems={institutions}
              value={form.institution}
              onChange={onChange}
              error={fieldErrors.institution}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <CustomSelect
              labelText="Escolaridad"
              id="scholarship"
              formControlProps={{
                fullWidth: true
              }}
              selectProps={{
                name: "scholarship"
              }}
              menuItems={scholarships}
              value={form.scholarship}
              onChange={onChange}
              error={fieldErrors.scholarship}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <CustomSelect
              labelText="Tipo de comunicación"
              id="communication"
              formControlProps={{
                fullWidth: true
              }}
              selectProps={{
                name: "communication"
              }}
              menuItems={communications}
              value={form.communication}
              onChange={onChange}
              error={fieldErrors.communication}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          {form.communication !== 4 &&
            <Fragment>
              <GridItem xs={12} sm={12} md={4}>
                <CustomSelect
                  labelText="Momento de pérdida auditiva"
                  id="auditoryLossMoment"
                  formControlProps={{
                    fullWidth: true
                  }}
                  selectProps={{
                    name: "auditoryLossMoment"
                  }}
                  menuItems={auditoryLossMoments}
                  value={form.auditoryLossMoment}
                  onChange={onChange}
                  error={fieldErrors.auditoryLossMoment}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <CustomSelect
                  labelText="Grado de pérdida auditiva"
                  id="auditoryLossGrade"
                  formControlProps={{
                    fullWidth: true
                  }}
                  selectProps={{
                    name: "auditoryLossGrade"
                  }}
                  menuItems={auditoryLossGrades}
                  value={form.auditoryLossGrade}
                  onChange={onChange}
                  error={fieldErrors.auditoryLossGrade}
                />
              </GridItem>
            </Fragment>
          }
          <GridItem xs={12} sm={12} md={4}>
            <CustomMultipleSelect
              labelText="Diagnósticos"
              id="diagnoses"
              formControlProps={{
                fullWidth: true
              }}
              selectProps={{
                name: "diagnoses"
              }}
              menuItems={diagnoses}
              value={form.diagnoses}
              onChange={onChange}
              error={fieldErrors.diagnoses}
            />
          </GridItem>
        </GridContainer>
      </Fragment>
    )
  }
}

export default PlayerForm