import {
  drawerWidth,
  transition,
  container
} from "assets/jss/material-dashboard-react.jsx";
  
const appStyle = theme => ({
  wrapper: {
    position: "relative",
    top: "0",
    minHeight: "100vh"
  },
  mainPanel: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    maxHeight: "100%",
    width: "100%",
    overflowScrolling: "touch"
  },
  content: {
    marginTop: "70px",
    padding: "30px 15px",
    minHeight: "calc(100vh - 123px)"
  },
  container,
  titleContainer: {
    textAlign: "center",
    margin: "25px 70px"
  },
  title: {
    margin: "0",
    fontWeight: "500",
    color: "#6b6b6b"
  },
  subtitle: {
    fontWeight: "400",
    margin: "0",
    color: "#969696"
  },
  img: {
    width: "100%"
  },
  floatBox: {
    [theme.breakpoints.up('md')]: {
      position: "absolute",
      left: "40px"
    }
  },
  card: {
    boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.44)",
    borderRadius: "0px"
  },
  cardTitle: {
    fontWeight: "600"
  },
  logosContainer: {
    display: "flex",
    justifyContent: "space-between"
  },
  floatLogo: {
    position: "relative",
    opacity: "0.6",
    maxHeight: "105px",
    [theme.breakpoints.up('md')]: {
      position: "fixed",
      bottom: "10px",
      maxWidth: "10%"
    },
  },
  right: {
    right: "10px"
  }
});

export default appStyle;
  