// @material-ui/icons
import Person from "@material-ui/icons/Person";
import PersonAdd from "@material-ui/icons/PersonAdd";
import CloudDownload from "@material-ui/icons/CloudDownload";
import ExitToApp from "@material-ui/icons/ExitToApp";
import Apps from "@material-ui/icons/Apps";
// core components/views
import Institutions from "views/Institutions/Institutions.jsx";
import AddInstitution from "views/AddInstitution/AddInstitution.jsx";
import Players from "views/Players/Players.jsx";
import AddPlayer from "views/AddPlayer/AddPlayer.jsx";
import Users from "views/Users/Users.jsx";
import AddUser from "views/AddUser/AddUser.jsx";
import Exports from "views/Exports/Exports.js";
import Logout from "components/Logout/Logout.jsx";
import VGFInstitutions from "views/VGFInstitutions/VGFInstitutions.jsx";
import AddVGFInstitution from "views/AddVGFInstitution/AddVGFInstitution.jsx";

const dashboardRoutes = [
  {
    collapse: true,
    name: "Visor-BECS",
    rtlName: "صفحات",
    icon: Apps,
    state: "pageCollapse",
    views: [
      {
        path: "/instituciones",
        name: "Instituciones",
        rtlName: "صفحة الخطأ",
        mini: "I",
        rtlMini: "البريد",
        component: Institutions,
        layout: "/admin",
        grantTo: "CRUD VISOR-BECS INSTITUTIONS"
      },
      {
        path: "/agregar-institucion",
        name: "Agregar institución",
        rtlName: "صفحة الخطأ",
        mini: "A",
        rtlMini: "البريد",
        component: AddInstitution,
        layout: "/admin",
        grantTo: "CRUD VISOR-BECS INSTITUTIONS"
      },
      {
        path: "/estudiantes",
        name: "Estudiantes",
        rtlName: "صفحة الخطأ",
        mini: "E",
        rtlMini: "البريد",
        component: Players,
        layout: "/admin",
        grantTo: "CRUD VISOR-BECS STUDENTS"
      },
      {
        path: "/agregar-estudiante",
        name: "Agregar estudiante",
        rtlName: "صفحة الخطأ",
        mini: "E+",
        rtlMini: "البريد",
        component: AddPlayer,
        layout: "/admin",
        grantTo: "CRUD VISOR-BECS STUDENTS"
      }
    ]
  },
  {
    collapse: true,
    name: "POV",
    rtlName: "صفحات",
    icon: Apps,
    state: "componentsCollapse",
    views: [
      {
        path: "/pov-instituciones",
        name: "POV Instituciones",
        rtlName: "صفحة الخطأ",
        mini: "PI",
        rtlMini: "البريد",
        component: VGFInstitutions,
        layout: "/admin",
        grantTo: "CRUD VGF INSTITUTIONS"
      },
      {
        path: "/pov-agregar-institucion",
        name: "POV Agregar institución",
        rtlName: "صفحة الخطأ",
        mini: "PA",
        rtlMini: "البريد",
        component: AddVGFInstitution,
        layout: "/admin",
        grantTo: "CRUD VGF INSTITUTIONS"
      }
    ]
  },
  {
    path: "/administradores",
    name: "Administradores",
    rtlName: "صفحة الخطأ",
    icon: Person,
    component: Users,
    layout: "/admin",
    grantTo: "CRUD ADMIN ACCESS"
  },
  {
    path: "/agregar-administrador",
    name: "Agregar Administrador",
    rtlName: "صفحة الخطأ",
    icon: PersonAdd,
    component: AddUser,
    layout: "/admin",
    grantTo: "CRUD ADMIN ACCESS"
  },
  {
    path: "/exportables",
    name: "Exportables",
    rtlName: "الحاجيات",
    icon: CloudDownload,
    component: Exports,
    layout: "/admin"
  },
  {
    path: "/logout",
    name: "Salir",
    rtlName: "لوحة القيادة",
    icon: ExitToApp,
    component: Logout,
    layout: "/admin"
  }
];

export default dashboardRoutes;
