import React, { useEffect, useState } from "react";
import axios from "axios";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Table from "components/Table/Table.jsx";
import AlertDialog from "components/AlertDialog/AlertDialog.jsx";
import VGFInstitutionForm from "../../components/VGFInstitutionForm/VGFInstitutionForm";
import Button from "components/CustomButtons/Button.jsx";
// Icons
import Receipt from "@material-ui/icons/Receipt";
import DoneAll from "@material-ui/icons/DoneAll";
import Icon1 from "@material-ui/icons/Filter1";
import Icon2 from "@material-ui/icons/Filter2";
import Icon3 from "@material-ui/icons/Filter3";
// API - Client
import { client } from "../../Client";
//Helpers
import { isEmail } from "../../Helpers";

import { usbColor } from "assets/jss/material-dashboard-react.jsx";

import CustomTabs from "components/CustomTabs/CustomTabs";
import Domain from "@material-ui/icons/Domain";
import PictureAsPdf from "@material-ui/icons/PictureAsPdf";
import Filter9Plus from "@material-ui/icons/Filter9Plus";
import PeopleAlt from "@material-ui/icons/PeopleAlt";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";

import { BlobProvider } from "@react-pdf/renderer";
import Pdf from "views/VocationalGuidanceForm/Pdf";
import VGFGroupReport from "views/Reports/VGFGroupReport";
import VGFReport from "views/VocationalGuidanceForm/VGFReport";

const style = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  ticketsIcon: {
    color: usbColor,
  },
  ticketsInputGrid: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
  },
  ticketsInputBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  inputRoot: {
    textAlign: "center",
    fontSize: "3em",
    "&::after": {
      display: "none",
    },
    "&::-webkit-clear-button, &::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
      display: "none",
    },
  },
  ticketsButtons: {
    fontSize: "2em",
    backgroundColor: "#eee",
  },
  ticketsInfoBackground: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "3px",
    backgroundColor: "#eee",
    padding: "50px 15px",
  },
  ticketsInfoIcon: {
    fontSize: "2em",
    color: "#c1c1c1",
  },
  ticketsInfoCount: {
    fontSize: "2.5em",
    margin: "15px",
    color: usbColor,
    fontWeight: "bold",
  },
  ticketsInfoText: {
    margin: 0,
    fontWeight: "400",
  },
  // positionAbsolute: {
  //   position: "absolute",
  //   right: "0",
  //   top: "0",
  // },
  checkRoot: {
    padding: "14px",
    "&:hover": {
      backgroundColor: "unset",
    },
  },
  checked: {
    color: "green !important",
  },
  checkedIcon: {
    width: "20px",
    height: "20px",
    border: "1px solid rgba(0,0,0, .54)",
    borderRadius: "3px",
  },
  uncheckedIcon: {
    width: "0px",
    height: "0px",
    padding: "9px",
    border: "1px solid rgba(0,0,0, .54)",
    borderRadius: "3px",
  },
};

const CancelToken = axios.CancelToken;

const useStyles = makeStyles(style);
const terms = ["A", "B"];

export default function VGFInstitutions() {
  const classes = useStyles();

  const [tickets, setTickets] = useState(0);
  const [_sendingEmail, set_sendingEmail] = useState(false);

  const [institutions, setInstitutions] = useState([]);
  const [selectedInstitution, setSelectedInstitution] = useState({});
  const [fieldErrors, setFieldErrors] = useState({});

  const [departments, setDepartments] = useState([]);
  const [cities, setCities] = useState([]);

  const [_showTicketsDialog, set_showTicketsDialog] = useState(false);
  const [_showEditDialog, set_showEditDialog] = useState(false);
  const [_showRemoveDialog, set_showRemoveDialog] = useState(false);
  const [_showTestRemoveDialog, set_showTestRemoveDialog] = useState(false);
  const [_isLoading, set_isLoading] = useState(true);

  const [tests, setTests] = useState([]);
  const [selectedTest, setSelectedTest] = useState({});
  const [students, setStudents] = useState([]);
  const [checkedInstitutions, setCheckedInstitutions] = useState([]);
  const [checkedTests, setCheckedTests] = useState([]);
  const [tab, setTab] = useState(0);

  const [reportInfo, setReportInfo] = useState(null);
  const [reportData, setReportData] = useState(null);
  const [historyData, setHistoryData] = useState(null);
  const [reportType, setReportType] = useState({ type: null, id: null });

  const [institutionsPag, setInstitutionsPag] = useState({
    count: 0,
    rowsPerPage: 15,
    page: 0,
  });
  const [testsPag, setTestsPag] = useState({
    count: 0,
    rowsPerPage: 15,
    page: 0,
  });
  const [studentsPag, setStudentsPag] = useState({
    count: 0,
    rowsPerPage: 15,
    page: 0,
  });

  const [search, setSearch] = useState("");

  /**
   * SEARCH
   */

  const handleSearchChange = (value) => {
    setSearch(value);
  };

  const handleSearch = () => {
    const source = CancelToken.source();
    const axiosCancelToken = source.token;

    switch (tab) {
      case 0:
        getInstitutions(axiosCancelToken);
        break;

      case 1:
        getTests();
        break;

      case 2:
        getStudents();
        break;

      default:
        break;
    }
  };

  // PAGINATION
  const handleChangePage = (type, page) => {
    const upd = (prev) => ({
      ...prev,
      page,
    });

    switch (type) {
      case "INSTITUTIONS":
        setInstitutionsPag(upd);

        break;

      case "TESTS":
        setTestsPag(upd);

        break;

      case "STUDENTS":
        setStudentsPag(upd);

        break;

      default:
        break;
    }
  };

  useEffect(() => {
    const source = CancelToken.source();
    const axiosCancelToken = source.token;

    getInstitutions(axiosCancelToken);
  }, [institutionsPag.page, institutionsPag.rowsPerPage]);

  useEffect(() => {
    getTests();
  }, [testsPag.page, testsPag.rowsPerPage]);

  useEffect(() => {
    getStudents();
  }, [studentsPag.page, studentsPag.rowsPerPage]);

  const handleChangeRowsPerPage = (type, value) => {
    const upd = (prev) => ({
      ...prev,
      rowsPerPage: value,
    });

    switch (type) {
      case "INSTITUTIONS":
        setInstitutionsPag(upd);

        break;

      case "TESTS":
        setTestsPag(upd);

        break;

      case "STUDENTS":
        setStudentsPag(upd);

        break;

      default:
        break;
    }
  };

  const getTableData = (data) => {
    return data.map(({ tickets, used_tickets, vgf_records, ...rest }) => {
      return {
        check: (
          <Checkbox
            key="key"
            className={classes.positionAbsolute}
            tabIndex={-1}
            checked={checkedInstitutions.includes(rest.id)}
            onClick={() => handleToggleInstitutions(rest.id)}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot,
            }}
          />
        ),
        ...rest,
      };
    });
  };

  const getTableDataTests = (data) => {
    return data.map((props) => {
      return {
        check: (
          <Checkbox
            key={props.id}
            className={classes.positionAbsolute}
            tabIndex={-1}
            checked={checkedTests.includes(props.id)}
            onClick={() => handleToggleTests(props.id)}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot,
            }}
          />
        ),
        ...props,
      };
    });
  };

  const getTableDataStudents = (data) => {
    return data.map((props) => props);
  };

  const handleToggleInstitutions = (value) => {
    const currentIndex = checkedInstitutions.indexOf(value);
    const newChecked = [...checkedInstitutions];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, value);
    }
    setCheckedInstitutions(newChecked);
  };

  const handleToggleTests = (value) => {
    const currentIndex = checkedTests.indexOf(value);
    const newChecked = [...checkedTests];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, value);
    }
    setCheckedTests(newChecked);
  };

  const getInstitutions = (axiosCancelToken) => {
    const { rowsPerPage, page } = institutionsPag;

    set_isLoading(true);

    client
      .getVGFInstitutions(axiosCancelToken, rowsPerPage, page + 1, search)
      .then((res) => {
        setInstitutions(res.data);
        setInstitutionsPag((prev) => ({
          ...prev,
          count: res.total,
        }));
        set_isLoading(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    const source = CancelToken.source();
    const axiosCancelToken = source.token;

    client
      .getVGFInstitutions(axiosCancelToken)
      .then((res) => {
        setInstitutions(res.data);
        setInstitutionsPag((prev) => ({
          ...prev,
          count: res.total,
        }));
        set_isLoading(false);
      })
      .catch((error) => console.log(error));

    client.getDepartments(axiosCancelToken).then((res) => setDepartments(res));

    return () => source.cancel();
  }, []);

  useEffect(() => {
    const source = CancelToken.source();
    const axiosCancelToken = source.token;

    if (!!selectedInstitution.department) {
      client
        .getCitiesByDepartment(axiosCancelToken, selectedInstitution.department)
        .then((cities) => {
          setCities(cities);
        })
        .catch((error) => console.log(error));
    }

    return () => source.cancel();
  }, [selectedInstitution.department]);

  useEffect(() => {
    const callback = ({ vgf_test_id, ...rest }) => {
      setSelectedTest((prev) =>
        prev.id === vgf_test_id ? { ...prev, ...rest } : prev
      );

      setTests((prev) =>
        prev.map((test) =>
          test.id === vgf_test_id ? { ...test, ...rest } : test
        )
      );
    };
    client.listenUpdateTickets.on(callback);

    return () => client.listenUpdateTickets.off(callback);
  }, []);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setSelectedInstitution((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCloseDialog = () => {
    set_showRemoveDialog(false);
    set_showEditDialog(false);
    set_showTestRemoveDialog(false);
    set_showTicketsDialog(false);
  };

  /**
   * REMOVE INSTITUTION
   */
  const onRemove = (institution) => {
    setSelectedInstitution(institution);
    set_showRemoveDialog(true);
  };

  const handleInstitutionRemoveDialog = () => {
    removeInstitution();
    set_showRemoveDialog(false);
  };

  const removeInstitution = () => {
    let institutionId = selectedInstitution.id;
    client.removeVGFInstitution(institutionId).then(({ ok }) => {
      if (ok)
        setInstitutions((prev) =>
          prev.filter(({ id }) => id !== institutionId)
        );
    });
  };

  /**
   * EDIT INSTITUTION
   */

  const onEdit = (institution) => {
    setSelectedInstitution(institution);
    set_showEditDialog(true);
  };

  const handleInstitutionEditDialog = () => {
    editInstitution();
  };

  const editInstitution = () => {
    const fieldErrors = validate(selectedInstitution);
    setFieldErrors(fieldErrors);
    if (Object.keys(fieldErrors).length) return;

    client
      .updateVGFInstitution(selectedInstitution)
      .then(({ ok }) => {
        if (ok) {
          set_showEditDialog(false);
          setInstitutions((prev) => {
            return prev.map((institution) => {
              if (Number(institution.id) === Number(selectedInstitution.id))
                return selectedInstitution;
              return institution;
            });
          });
        }
      })
      .catch(({ response }) => {
        const fieldErrors = getFormErrors(response.data.message);
        setFieldErrors(fieldErrors);
      });
  };

  const validate = (form) => {
    const errors = {};
    if (!form.name) errors.name = "Nombre requerido";
    if (!form.responsible) errors.responsible = "Responsable requerido";
    if (!form.phone) errors.phone = "Teléfono requerido";
    if (!form.email) errors.email = "Correo requerido";
    if (!form.department) errors.department = "Departamento requerido";
    if (!form.city) errors.city = "Ciudad requerida";
    if (form.email && !isEmail(form.email)) errors.email = "Correo inválido";
    return errors;
  };

  const getFormErrors = (messages) => {
    const errors = {};
    for (const key in messages) {
      if (messages.hasOwnProperty(key)) {
        errors[key] = messages[key][0];
      }
    }
    return errors;
  };

  /**
   * REMOVE TESTS
   */

  const onRemoveTest = (test) => {
    setSelectedTest(test);
    set_showTestRemoveDialog(true);
  };

  const handleTestRemoveDialog = () => {
    removeTest();
    set_showTestRemoveDialog(false);
  };

  const removeTest = () => {
    let testId = selectedTest.id;
    client.removeVGFTest(testId).then(({ ok }) => {
      if (ok) setTests((prev) => prev.filter(({ id }) => id !== testId));
    });
  };

  /**
   * TICKETS
   */

  const onTickets = ({ id }) => {
    const selected = tests.find((test) => test.id === id);
    setSelectedTest(selected);
    set_showTicketsDialog(true);
  };

  const updateTickets = (increments, testId) => {
    client.updateTickets(increments, testId);
    setTickets(0);
  };

  const sendEmail = (testId) => {
    client.sendInstitutionCodeByEmail(testId).then(({ ok }) => {
      if (ok) set_sendingEmail(false);
    });
  };

  const handleTabChange = (event, value) => {
    setTab(value);

    if (value === 1) {
      getTests(true);
    } else if (value === 2) {
      getStudents(true);
    }
  };

  const getTests = (initial = false) => {
    const source = CancelToken.source();
    const axiosCancelToken = source.token;

    const { rowsPerPage, page } = testsPag;
    set_isLoading(true);

    const searchVal = initial ? "" : search;

    client
      .getVGFTestsByInstitutionId(
        axiosCancelToken,
        checkedInstitutions,
        rowsPerPage,
        page,
        searchVal
      )
      .then((res) => {
        setTests(res.data);
        setTestsPag((prev) => ({
          ...prev,
          count: res.total,
        }));
        set_isLoading(false);
      });
  };

  const getStudents = (initial = false) => {
    const source = CancelToken.source();
    const axiosCancelToken = source.token;

    const { rowsPerPage, page } = studentsPag;
    set_isLoading(true);

    const searchVal = initial ? "" : search;

    client
      .getVGFStudentsByTestId(
        axiosCancelToken,
        checkedInstitutions,
        checkedTests,
        rowsPerPage,
        page,
        searchVal
      )
      .then((res) => {
        setStudents(res.data);
        setStudentsPag((prev) => ({
          ...prev,
          page: res.current_page,
          count: res.total,
        }));
        set_isLoading(false);
      });
  };

  const handleStudentReport = (id) => {
    setReportType({
      type: "student",
      id,
    });

    client.getVGFStudentReportResults(id).then(({ info, results, history }) => {
      setReportInfo(info);
      setReportData(results);
      setHistoryData(history);
    });
  };

  const handleReport = (type, id, year) => {
    setReportType({
      type,
      id,
    });

    client.getVGFReportResults(type, id, year).then(({ info, results }) => {
      setReportInfo(info);
      setReportData(results);
    });
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomTabs
            title="Ver:"
            headerColor="warning"
            value={tab}
            handleChange={handleTabChange}
            tabs={[
              {
                tabName: "Instituciones",
                tabIcon: <Domain />,
                tabContent: (
                  <Table
                    tableHeaderColor="warning"
                    hideID={true}
                    tableHead={[
                      "",
                      "Nombre",
                      "Responsable",
                      "Teléfono",
                      "Correo",
                      "Calendario",
                      "Dpto",
                      "Ciudad",
                    ]}
                    tableData={getTableData(institutions)}
                    onRemove={onRemove}
                    onEdit={onEdit}
                    loading={_isLoading}
                    customActions={[
                      {
                        tooltip: "Último año",
                        icon: Icon1,
                        iconStyle: classes.ticketsIcon,
                        onClick: ({ id }) => handleReport("institution", id, 1),
                      },
                      {
                        tooltip: "Año pasado",
                        icon: Icon2,
                        iconStyle: classes.ticketsIcon,
                        onClick: ({ id }) => handleReport("institution", id, 2),
                      },
                      {
                        tooltip: "Hace 2 años",
                        icon: Icon3,
                        iconStyle: classes.ticketsIcon,
                        onClick: ({ id }) => handleReport("institution", id, 3),
                      },
                    ]}
                    paginationProps={{
                      rowsPerPageOptions: [10, 15, 20, 25],
                      count: institutionsPag.count,
                      rowsPerPage: institutionsPag.rowsPerPage,
                      page: institutionsPag.page,
                      onChangePage: (evt, page) =>
                        handleChangePage("INSTITUTIONS", page),
                      onChangeRowsPerPage: (evt) =>
                        handleChangeRowsPerPage(
                          "INSTITUTIONS",
                          evt.target.value
                        ),
                    }}
                    search={{
                      value: search,
                      placeholder: "Nombre o fecha",
                      onChange: (evt) => handleSearchChange(evt.target.value),
                      onClick: handleSearch,
                    }}
                  />
                ),
              },
              {
                tabName: "Grados",
                tabIcon: <Filter9Plus />,
                tabContent: (
                  <Table
                    tableHeaderColor="warning"
                    hideID={true}
                    tableHead={[
                      "",
                      "Grado",
                      "Código",
                      "Tickets",
                      "Usos",
                      "N° de Estudiantes",
                      "Registros",
                      "Fecha",
                    ]}
                    tableData={getTableDataTests(tests)}
                    onRemove={onRemoveTest}
                    loading={_isLoading}
                    customActions={[
                      {
                        tooltip: "Tickets",
                        icon: Receipt,
                        iconStyle: classes.ticketsIcon,
                        onClick: onTickets,
                      },
                      {
                        tooltip: "Reporte",
                        icon: PictureAsPdf,
                        iconStyle: classes.ticketsIcon,
                        onClick: ({ id }) => handleReport("test", id),
                      },
                    ]}
                    paginationProps={{
                      rowsPerPageOptions: [10, 15, 20, 25],
                      count: testsPag.count,
                      rowsPerPage: testsPag.rowsPerPage,
                      page: testsPag.page,
                      onChangePage: (evt, page) =>
                        handleChangePage("TESTS", page),
                      onChangeRowsPerPage: (evt) =>
                        handleChangeRowsPerPage("TESTS", evt.target.value),
                    }}
                    search={{
                      value: search,
                      placeholder: "Grado o fecha",
                      onChange: (evt) => handleSearchChange(evt.target.value),
                      onClick: handleSearch,
                    }}
                  />
                ),
              },
              {
                tabName: "Estudiantes",
                tabIcon: <PeopleAlt />,
                tabContent: (
                  <Table
                    tableHeaderColor="warning"
                    tableHead={[
                      "ID",
                      "Nombre",
                      "Apellido",
                      "Correo",
                      "Teléfono",
                      "F. de Nacimiento",
                      "Grado",
                      "Última aplicación",
                    ]}
                    tableData={getTableDataStudents(students)}
                    loading={_isLoading}
                    customActions={[
                      {
                        tooltip: "Reporte",
                        icon: PictureAsPdf,
                        iconStyle: classes.ticketsIcon,
                        onClick: ({ id }) => handleStudentReport(id),
                      },
                    ]}
                    paginationProps={{
                      rowsPerPageOptions: [10, 15, 20, 25],
                      count: studentsPag.count,
                      rowsPerPage: studentsPag.rowsPerPage,
                      page: studentsPag.page,
                      onChangePage: (evt, page) =>
                        handleChangePage("STUDENTS", page),
                      onChangeRowsPerPage: (evt) =>
                        handleChangeRowsPerPage("STUDENTS", evt.target.value),
                    }}
                    search={{
                      value: search,
                      placeholder: "Nombre, email, etc.",
                      onChange: (evt) => handleSearchChange(evt.target.value),
                      onClick: handleSearch,
                    }}
                  />
                ),
              },
            ]}
          />
        </GridItem>
      </GridContainer>
      {/** TICKETS DIALOG */}
      <Dialog
        open={_showTicketsDialog}
        onClose={handleCloseDialog}
        aria-labelledby="max-width-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">{selectedTest.code}</DialogTitle>
        <DialogContent>
          <GridContainer justify="space-between" spacing={5}>
            <GridItem
              xs={12}
              sm={4}
              md={4}
              className={classes.ticketsInputGrid}
            >
              <div className={classes.ticketsInputBox}>
                <Button
                  className={classes.ticketsButtons}
                  onClick={() => setTickets((prev) => prev - 1)}
                >
                  -
                </Button>
                <TextField
                  id="tickets"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={tickets}
                  onChange={(e) => setTickets(Number(e.target.value))}
                  InputProps={{
                    classes: {
                      input: classes.inputRoot,
                    },
                    disableUnderline: true,
                  }}
                />
                <Button
                  className={classes.ticketsButtons}
                  onClick={() => setTickets((prev) => prev + 1)}
                >
                  +
                </Button>
              </div>
              <Button
                variant="outlined"
                className={classes.button}
                color="usb"
                size="lg"
                fullWidth
                onClick={() => updateTickets(tickets, selectedTest.id)}
              >
                Añadir Tickets
              </Button>
              <Button
                variant="outlined"
                className={classes.button}
                color="usb"
                size="lg"
                fullWidth
                disabled={_sendingEmail}
                onClick={() => sendEmail(selectedTest.id)}
              >
                {_sendingEmail ? "Enviando..." : "Notificar al correo"}
              </Button>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <div className={classes.ticketsInfoBackground}>
                <Receipt className={classes.ticketsInfoIcon} />
                <p className={classes.ticketsInfoCount}>{`${
                  selectedTest.tickets
                }/${selectedTest.tickets + selectedTest.used_tickets}`}</p>
                <p className={classes.ticketsInfoText}>Tickets disponibles</p>
              </div>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <div className={classes.ticketsInfoBackground}>
                <DoneAll className={classes.ticketsInfoIcon} />
                <p className={classes.ticketsInfoCount}>
                  {selectedTest.vgf_records}
                </p>
                <p className={classes.ticketsInfoText}>Pruebas registradas</p>
              </div>
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cerrar</Button>
        </DialogActions>
      </Dialog>
      {/** INSTITUTION EDIT DIALOG */}
      <AlertDialog
        open={_showEditDialog}
        onClose={handleCloseDialog}
        onAgree={handleInstitutionEditDialog}
        dialogTitle={`${selectedInstitution.name}`}
        dialogContent={
          <VGFInstitutionForm
            form={selectedInstitution}
            onChange={handleChange}
            terms={terms}
            departments={departments}
            cities={cities}
            fieldErrors={fieldErrors}
          />
        }
        dialogProps={{
          fullWidth: true,
          maxWidth: "md",
        }}
      />
      {/** INSTITUTION REMOVE DIALOG */}
      <AlertDialog
        open={_showRemoveDialog}
        onClose={handleCloseDialog}
        onAgree={handleInstitutionRemoveDialog}
        dialogTitle={`${selectedInstitution.name}`}
        dialogDescription={`¿Está seguro que desea eliminar esta institución?. Esta acción es irreversible`}
        remove
      />
      {/** TEST REMOVE DIALOG */}
      <AlertDialog
        open={_showTestRemoveDialog}
        onClose={handleCloseDialog}
        onAgree={handleTestRemoveDialog}
        dialogTitle={`${selectedTest.code}`}
        dialogDescription={`¿Está seguro que desea eliminar este grupo?. Esta acción es irreversible`}
        remove
      />

      {reportData && reportType.type === "student" && (
        <div style={{ position: "absolute", overflow: "hidden", height: 0 }}>
          <VGFReport
            data={reportData}
            historyData={historyData}
            email={false}
            downloaded={() => {
              setReportInfo(null);
              setReportData(null);
              setHistoryData(null);
            }}
          />
        </div>
      )}
      {reportData && reportType.type !== "student" && (
        <div style={{ position: "absolute", overflow: "hidden", height: 0 }}>
          <VGFGroupReport
            info={reportInfo}
            data={reportData}
            downloaded={() => {
              setReportInfo(null);
              setReportData(null);
            }}
          />
        </div>
      )}
    </div>
  );
}
