import React, { useState } from "react";
// Charts
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
  Text,
} from "recharts";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// images
import usb from "assets/img/usb.jpg";
import labpsicologia from "assets/img/labpsicologia.jpg";
//PDF
import Pdf from "./Pdf";
import { BlobProvider } from "@react-pdf/renderer";
import html2canvas from "html2canvas";

// API - Client
import { client } from "Client";

import { usbColor } from "assets/jss/material-dashboard-react.jsx";

const useStyles = makeStyles((theme) => ({
  document: {
    [theme.breakpoints.up("md")]: {
      height: 631 * 2,
      marginTop: "5px",
      padding: "0 40px",
    },
  },
  logosContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  logo: {
    display: "inline-block",
    width: "120px",
  },
  name: {
    margin: "0",
    fontWeight: "500",
  },
  info: {
    margin: "0",
  },
  badge: {
    backgroundColor: usbColor,
    padding: "3px 10px",
    borderRadius: "5px",
    fontSize: "15px",
    color: "#fff",
  },
  programBadge: {
    display: "inline-block",
    margin: "0px",
    backgroundColor: usbColor,
    textAlign: "center",
    borderRadius: "5px",
    color: "white",
    padding: "0 10px",
  },
  programContainer: {
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
  },
  graphContainer: {
    padding: "0 !important",
    [theme.breakpoints.down("md")]: {
      marginTop: "15px",
    },
  },
  table: {
    tableLayout: "fixed",
    borderSpacing: "0",
    fontSize: "1.2em",
    width: "100%",
    height: "100%",
  },
  tableHeadTr: {
    backgroundColor: usbColor,
    color: "#fff",
    textAlign: "left",
    fontWeight: "bold",
  },
  tableBodyTr: {
    backgroundColor: "#f5f5f5",
  },
  tableDivider: {
    borderRight: "1px solid #eee",
  },
  tableThTd: {
    padding: "12px 15px 3px 15px",
    textAlign: "center",
    width: "50%",
  },
  tableNameThTd: {
    padding: "3px 3px",
    textAlign: "center",
  },
  tableScore: {
    fontSize: "1.5em",
    fontWeight: "bold",
    margin: "5px",
  },
  tableName: {
    fontSize: "0.8em",
  },
  listStyle: {
    "&::marker": {
      color: usbColor,
      fontSize: "1.5em",
    },
  },
  footer: {
    textAlign: "center",
  },
}));

const getProgramMessage = (programs) => {
  const groupsIds = programs.map(({ id }) => id);
  const isMultipleGroups = new Set(groupsIds).size > 1;

  if (isMultipleGroups) {
    return "Nuestra prueba detectó inclinación hacia varios grupos de carreras. Sin embargo, no encontramos ninguna que se destaque. Parece que tienes unos gustos muy amplios. Te sugerimos consultar con un profesional especializado en orientación vocacional y profesional.";
  } else {
    return "Felicitaciones, nuestra prueba detectó una inclinación muy clara por este grupo de carreras.";
  }
};

export default function VGFReport(props) {
  const classes = useStyles();

  const {
    data: { threshold, student, areas, programs },
    historyData,
    email,
    downloaded = () => {},
  } = props;

  const [graph, setGraph] = useState(null);

  const areaThreshold = threshold?.code === 1;
  const bestArea = areaThreshold
    ? null
    : areas.reduce((prev, current) =>
        prev.score > current.score ? prev : current
      );

  const graphToImage = () => {
    html2canvas(document.getElementById("graph"), {
      scale: 2,
      scrollY: -window.scrollY,
    }).then((canvas) => {
      setGraph(canvas.toDataURL("image/jpeg"));
    });
  };

  const sendEmail = (base64data, area, programs) => {
    client.sendResultsByEmail(student.email, base64data, area, programs);
  };

  const handleBlob = ({ blob, url, loading, error }) => {
    if (!loading) {
      if (email) {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64data = reader.result;
          sendEmail(base64data, bestArea, programs);
        };
      } else {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `${student.name} ${student.last_name}`;
        document.body.append(link);
        link.click();
        link.remove();
        setTimeout(() => URL.revokeObjectURL(link.href), 7000);
        downloaded();
      }
    }

    return null;
  };

  const CustomizedAxisTick = ({ x, y, payload }) => {
    const cos = Math.cos((-payload.coordinate * Math.PI) / 180);
    const round = Math.round(cos * 1000) / 1000;
    let position;

    if (round > 0) {
      position = "start";
    } else if (round < 0) {
      position = "end";
    } else {
      position = "middle";
    }

    return (
      <Text
        x={x}
        y={y}
        width={100}
        textAnchor={position}
        verticalAnchor="middle"
      >
        {payload.value}
      </Text>
    );
  };

  const area = () => {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={7}>
          {areaThreshold ? (
            <p style={{ margin: "0" }}>
              Nuestra prueba no detectó ninguna inclinación definida en tus
              respuestas. Te sugerimos consultar con un profesional
              especializado en orientación vocacional y profesional.
            </p>
          ) : (
            <div>
              <div style={{ display: "flex", alignItems: "center" }}>
                {/* <img src={lamp} alt="faculty" className={classes.logo} /> */}
                <div>
                  <small className={classes.badge}>Área</small>
                  <h3 style={{ margin: "0" }}>{bestArea?.area}</h3>
                </div>
              </div>
              <p style={{ margin: "0" }}>{bestArea?.description}</p>
            </div>
          )}
        </GridItem>
        <GridItem
          id="graph"
          xs={12}
          sm={12}
          md={5}
          className={classes.graphContainer}
        >
          <ResponsiveContainer minHeight={200}>
            <RadarChart cx="50%" data={areas}>
              <PolarGrid />
              <PolarAngleAxis dataKey="area" tick={<CustomizedAxisTick />} />
              <PolarRadiusAxis
                domain={[0, 12]}
                tickCount={4}
                axisLine={false}
                tick={false}
              />
              <Radar
                dataKey="score"
                stroke={usbColor}
                fill={usbColor}
                fillOpacity={0.6}
                onAnimationEnd={graphToImage}
              />
            </RadarChart>
          </ResponsiveContainer>
        </GridItem>
      </GridContainer>
    );
  };

  const groups = () => {
    if (areaThreshold) return null;

    const programThreshold = threshold?.code === 2;

    return (
      <div>
        <hr />
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <h4>Programas sugeridos:</h4>
            {programThreshold ? (
              <p>
                Nuestra prueba no detectó ninguna inclinación definida en tus
                respuestas de profundización. Te sugerimos consultar con un
                profesional especializado en orientación vocacional y
                profesional.
              </p>
            ) : (
              <div>
                <p>{getProgramMessage(programs)}</p>
                <ul>
                  {programs.map((program, index) => (
                    <li key={index} className={classes.listStyle}>
                      <h5 className={classes.programBadge}>{program.name}</h5>
                      <p style={{ textAlign: "justify" }}>
                        {program.description}
                      </p>
                      {program.url && (
                        <p>
                          Conoce más sobre este programa aquí:{" "}
                          <a href={program.url}>{program.url}</a>
                        </p>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </GridItem>
        </GridContainer>
      </div>
    );
  };

  const getHistoryMessage = () => {
    if (!historyData) return null;

    const { threshold, areas } = historyData;

    const h_areaThreshold = threshold?.code === 1;

    if (h_areaThreshold) return null;

    const h_bestArea = areas.reduce((prev, current) =>
      prev.score > current.score ? prev : current
    );

    if (h_bestArea?.id === bestArea?.id) {
      return "¡Tus resultados se mantienen desde la última vez que presentaste esta prueba!";
    } else {
      return `La inclinación detectada es diferente de la última vez que presentaste esta prueba. La vez anterior obtuviste ${h_bestArea?.area}`;
    }
  };

  const history = () => {
    if (!historyData) return null;

    const historyMessage = getHistoryMessage();

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <p>
            <b>Histórico: </b> {historyMessage}
          </p>
        </GridItem>
      </GridContainer>
    );
  };

  return (
    <div>
      <GridContainer
        direction="row-reverse"
        justify="space-between"
        alignItems="flex-start"
      >
        <GridItem xs={12} sm={12} md={4} className={classes.logosContainer}>
          <img
            src={labpsicologia}
            alt="labpsicologia"
            className={classes.logo}
          />
          <img src={usb} alt="usb" className={classes.logo} />
        </GridItem>
        <GridItem xs={12} sm={12} md={8}>
          <h3 className={classes.name}>
            Brújula: Prueba de intereses vocacionales
          </h3>
          <h4
            className={classes.name}
          >{`${student.name} ${student.last_name}`}</h4>
          <p className={classes.info}>Grado: {student.scholarship}</p>
          <p className={classes.info}>Edad: {student.age}</p>
          <p className={classes.info}>Institución: {student.institution}</p>
        </GridItem>
      </GridContainer>
      {history()}
      <hr />
      {area()}
      {groups()}
      <hr />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} className={classes.footer}>
          <p>
            Estudiar en la Universidad de San Buenaventura Cali ES POSIBLE
            formando PROFESIONALES CON FUTURO.
          </p>
          <p>
            Conoce nuestras becas en{" "}
            <a href="https://www.usbcali.edu.co/mas-beneficios/becas">
              https://www.usbcali.edu.co/mas-beneficios/becas
            </a>
          </p>
        </GridItem>
      </GridContainer>
      {
        (!areaThreshold || email === false) && !!graph && (
          <BlobProvider
            document={
              <Pdf
                data={{
                  threshold,
                  student,
                  areas,
                  programs,
                  graph,
                  historyMessage: getHistoryMessage(),
                }}
              />
            }
          >
            {handleBlob}
          </BlobProvider>
        )
        // <PDFViewer width="835" height="1104">
        //   <Pdf data={{ threshold, student, areas, programs, graph }} />
        // </PDFViewer>
      }
    </div>
  );
}
