import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import LinearProgress from '@material-ui/core/LinearProgress';
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import CreateIcon from '@material-ui/icons/Create';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
// core components
import customStepperStyle from "assets/jss/material-dashboard-react/components/customStepperStyle.js";

function CustomStepper(props) {
  const { steps, current, progress, classes } = props;
  
  const icons = [
  <LibraryBooksIcon className={classes.icon} />,
  <CreateIcon className={classes.icon} />,
  <FormatListBulletedIcon className={classes.icon} />
]

  function StepContent({ done, index }) {
    return done ? "✓" : icons[index];
  }

  const ProgressBar = ({ current, step, progress }) => {
    let value = 0;
    if(current+1 === step) {
      value = progress
    } else if(current >= step) {
      value = 100
    }
     
    return <LinearProgress variant="determinate" value={value} classes={{root: classes.linearProgress, bar: classes.bar}} />
  }
  
  function renderStep(label, key) {
    const { current, progress } = this;
    const done = key < current;
    const currentStep = key === current;
    const stepClasses = classNames({
      [classes.stepper__step__index]: true,
      [classes.currentStep]: currentStep,
      [classes.done]: done
    });
  
    return (
      <li className={classes.stepper__step} key={key}>
        <div className={classes.labelContainer}>
          <span className={stepClasses}>
              <StepContent done={done} index={key} />
          </span>
          <p className={classes.stepper__step__label}>{label}</p>
        </div>
        {!!key && <ProgressBar current={current} step={key} progress={progress} />}
      </li>
    )
  }

  return (
    <ul className={classes.stepper}>
      {steps.map(renderStep, { current, progress })}
    </ul>
  )
}

CustomStepper.propTypes = {
  classes: PropTypes.object.isRequired,
  steps: PropTypes.array.isRequired,
  current: PropTypes.number.isRequired,
  progress: PropTypes.number
};

export default withStyles(customStepperStyle)(CustomStepper);