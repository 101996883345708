import React, { Component, Fragment } from "react";
import axios from "axios";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Table from "components/Table/Table.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import AlertDialog from "components/AlertDialog/AlertDialog.jsx";
import PlayerForm from "../../components/PlayerForm/PlayerForm";
// API - Client
import { client } from "../../Client";

const CancelToken = axios.CancelToken;
let source;
let axiosCancelToken;

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class Players extends Component {
  
  state = {
    players: [],
    selectedPlayer: {},
    fieldErrors: {},
    scholarships: [],
    genres: [],
    communications: [],
    institutions: [],
    auditoryLossMoments: [],
    auditoryLossGrades: [],
    diagnoses: [],
    pagination: {
      count: 0,
      rowsPerPage: 20,
      page: 0
    },
    search: '',
    _showEditDialog: false,
    _showRemoveDialog: false,
    _savedNotification: false,
    _isLoading: false
  }

  componentDidMount() {
    source = CancelToken.source();
    axiosCancelToken = source.token;
    
    this.getPlayers();
    this.getScholarships()
    this.getGenres()
    this.getCommunications()
    this.getInstitutions()
    this.getAuditoryLossMoments()
    this.getAuditoryLossGrades()
    this.getDiagnoses()
  }

  componentWillUnmount() {
    source.cancel();
  }

  getPlayers = () => {
    this.setState({ _isLoading: true })

    client.getPlayers(axiosCancelToken,this.state.pagination.rowsPerPage, this.state.pagination.page + 1, this.state.search).then((response) => {

      this.setState(prevState => ({
        players: response.data,
        pagination: {
          ...prevState.pagination,
          count: response.total
        },
        _isLoading: false
      }));
      
    }).catch(error => {})
  }

  getTableData = (data) => {
    return data.map(({ id, username, name, last_name, institution, scholarship, communication, genre, age,
      auditory_loss_moment, auditory_loss_grade, diagnoses }) => {

      let fullName = name+" "+last_name
        
      return {
        id,
        username,
        fullName,
        institution: institution.name,
        scholarship: scholarship.name,
        communication: communication.name,
        genre: genre.name,
        age,
        auditoryLossMoment: (auditory_loss_moment)? auditory_loss_moment.name : "",
        auditoryLossGrade: (auditory_loss_grade)? auditory_loss_grade.name : "",
        diagnoses: this.getDiagnosesNames(diagnoses)
      }
    });
  }

  getDiagnosesNames = (diagnoses) => {
    return diagnoses.map((diagnosis) => {
      return diagnosis.name;
    });
  }

  getScholarships = () => {
    client.getScholarships(axiosCancelToken).then(scholarships => {
      this.setState({ scholarships })
    }).catch(error => {})
  }

  getGenres = () => {
    client.getGenres(axiosCancelToken).then(genres => {
      this.setState({ genres })
    }).catch(error => {})
  }

  getCommunications = () => {
    client.getCommunications(axiosCancelToken).then(communications => {
      this.setState({ communications })
    }).catch(error => {})
  }

  getInstitutions = () => {
    client.getInstitutions(axiosCancelToken).then(institutions => {
      this.setState({ institutions })
    }).catch(error => {})
  }

  getAuditoryLossMoments = () => {
    client.getAuditoryLossMoments(axiosCancelToken).then(auditoryLossMoments => {
      this.setState({ auditoryLossMoments })
    }).catch(error => {})
  }

  getAuditoryLossGrades = () => {
    client.getAuditoryLossGrades(axiosCancelToken).then(auditoryLossGrades => {
      this.setState({ auditoryLossGrades })
    }).catch(error => {})
  }

  getDiagnoses = () => {
    client.getDiagnoses(axiosCancelToken).then(diagnoses => {
      this.setState({ diagnoses })
    }).catch(error => {})
  }

  handleChange = (evt) => {
    const name = evt.target.name;
    const value = evt.target.value;

    this.setState(prevState => ({
      selectedPlayer: {
        ...prevState.selectedPlayer,
        [name]: value
      }
    }));

    if ( name === "communication" && value === 4 ) this.resetAuditoryFields()
  }

  resetAuditoryFields = () => {
    this.setState(prevState => ({
      selectedPlayer: {
        ...prevState.selectedPlayer,
        auditoryLossMoment: "",
        auditoryLossGrade: ""
      }
    }));
  }

  handleCloseDialog = () => {
    this.setState({ _showRemoveDialog: false, _showEditDialog: false })
  }

  /**
   * SEARCH
   */

  handleSearchChange = (evt) => {
    this.setState({ search: evt.target.value });
  }

  handleSearch = () => {
    this.getPlayers()
  }

  /**
   * REMOVE PLAYER FLOW
   */
  onRemove = (player) => {
    this.setState({ _showRemoveDialog: true, selectedPlayer: player })
  }

  handleRemoveDialog = () => {
    this.removePlayer()
    this.setState({ _showRemoveDialog: false })
  }

  removePlayer = () => {
    let playerId = this.state.selectedPlayer.id
    client.removePlayer(playerId).then(({ ok }) => {
      if (ok) {
        this.setState({
          players: this.state.players.filter(({ id }) => id !== playerId)
        })
      }
    })
  }

  /**
   * EDIT PLAYER FLOW
   */

  onEdit = (player) => {
    let selectedPlayer = this.state.players.find(({ id }) => id === player.id)
    this.setState({
      _showEditDialog: true,
      selectedPlayer: {
        id: selectedPlayer.id,
        username: selectedPlayer.username,
        password: "",
        name: selectedPlayer.name,
        lastName: selectedPlayer.last_name,
        birthdate: selectedPlayer.birthdate,
        scholarship: selectedPlayer.scholarship_id,
        genre: selectedPlayer.genre_id,
        communication: selectedPlayer.communication_id,
        institution: selectedPlayer.institution_id,
        auditoryLossMoment: selectedPlayer.auditory_loss_moment_id,
        auditoryLossGrade: selectedPlayer.auditory_loss_grade_id,
        diagnoses: this.getDiagnosesNames(selectedPlayer.diagnoses)
      }
    })
  }

   handleEditDialog = () => {
    this.editPlayer()
   }

   editPlayer = () => {
    let selectedPlayer = this.state.selectedPlayer
    const fieldErrors = this.validate(selectedPlayer)
    this.setState({ fieldErrors })
    if( Object.keys(fieldErrors).length ) return;

    client.updatePlayer(selectedPlayer).then(({ ok }) => {
      if (ok) {
        this.getPlayers()
        this.setState({ _showEditDialog: false })
      }
    }).catch(({ response }) => {
      const fieldErrors = this.getFormErrors(response.data.message)
      this.setState({ fieldErrors })
    })
   }

   validate = (form) => {
    const errors = {};
    if (!form.username) errors.username = 'Usuario requerido';
    if (!form.name) errors.name = 'Nombre requerido';
    if (!form.lastName) errors.lastName = 'Apellido requerido';
    if (!form.birthdate) errors.birthdate = 'Fecha de nacimiento requerida';
    if (!form.scholarship) errors.scholarship = 'Escolaridad requerida';
    if (!form.genre) errors.genre = 'Género requerido';
    if (!form.communication) errors.communication = 'Tipo de comunicación requerida';
    if (!form.institution) errors.institution = 'Institución requerida';
    return errors;
  };

  getFormErrors = (messages) => {
    const errors = {}
    for (const key in messages) {
      if (messages.hasOwnProperty(key)) {
        errors[key] = messages[key][0]        
      }
    }
    return errors
  }

   // PAGINATION
   handleChangePage = (evt, page) => {
    this.setState(prevState => ({
      pagination: {
        ...prevState.pagination,
        page
      }
    }), () => this.getPlayers() )
   }

   handleChangeRowsPerPage = (evt) => {    
    this.setState(prevState => ({
      pagination: {
        ...prevState.pagination,
        rowsPerPage: evt.target.value
      }
    }), () => this.getPlayers() )
   }
  
  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="warning">
                <h4 className={classes.cardTitleWhite}>Estudiantes</h4>
                <p className={classes.cardCategoryWhite}>
                  Listado de estudiantes registrados
                </p>
              </CardHeader>
              <CardBody>
                <Table
                  tableHeaderColor="warning"
                  tableHead={["ID", "Usuario", "Nombre", "Institución", "Grado", "Comunicación", "Género", "Edad", "Momento pérdida auditiva", "Grado pérdida auditiva", "Diagnósticos"]}
                  tableData={ this.getTableData(this.state.players) }
                  onRemove={this.onRemove}
                  onEdit={this.onEdit}
                  paginationProps={{
                    rowsPerPageOptions: [10, 15, 20, 25],
                    count: this.state.pagination.count,
                    rowsPerPage: this.state.pagination.rowsPerPage,
                    page: this.state.pagination.page,
                    onChangePage: this.handleChangePage,
                    onChangeRowsPerPage: this.handleChangeRowsPerPage
                  }}
                  search={{
                    value: this.state.search,
                    placeholder: "ID, Usuario o Nombre",
                    onChange: this.handleSearchChange,
                    onClick: this.handleSearch
                  }}
                  loading={this.state._isLoading}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <AlertDialog
          open={this.state._showRemoveDialog}
          onClose={this.handleCloseDialog}
          onAgree={this.handleRemoveDialog}
          dialogTitle={`${this.state.selectedPlayer.username}`}
          dialogDescription={`¿Está seguro que desea eliminar este estudiante?. Esta acción es irreversible`}
          remove
        />
        <AlertDialog
          open={this.state._showEditDialog}
          onClose={this.handleCloseDialog}
          onAgree={this.handleEditDialog}
          dialogTitle={`${this.state.selectedPlayer.username}`}
          dialogContent={
            <PlayerForm form={this.state.selectedPlayer} onChange={this.handleChange} scholarships={this.state.scholarships}
            genres={this.state.genres} communications={this.state.communications} institutions={this.state.institutions}
            auditoryLossMoments={this.state.auditoryLossMoments} auditoryLossGrades={this.state.auditoryLossGrades}
            diagnoses={this.state.diagnoses} fieldErrors={this.state.fieldErrors} />
          }
          dialogProps={{
            fullWidth: true,
            maxWidth: "md"
          }}
        />
      </Fragment>
    );
  }
}

export default withStyles(styles)(Players);
