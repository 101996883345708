import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import CloudDownload from "@material-ui/icons/CloudDownload";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ButtonGroup from "components/ButtonGroup/ButtonGroup.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";

import BCSReport from "views/Reports/BCSReports.jsx";
// API - Client
import { client } from "../../Client";

import sayri from "assets/img/sayri.png";
import totem from "assets/img/totem.png";
import puzzle from "assets/img/puzzle.png";
import vgf from "assets/img/vgf.png";
import becs from "assets/img/becs.png";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import VGFGlobalReport from "views/Reports/VGFGlobalReport";

const communications = [
  { id: "deaf", name: "Sordo" },
  { id: "hearer", name: "Oyente" },
  { id: "all", name: "Ambos" },
];

const simpleCards = (token) => [
  {
    name: "Juego principal: General",
    description: "Recopilación de datos general por intento",
    image: sayri,
    color: "success",
    url: `/api/admin/export-general-csv?api_token=${token}`,
    grantTo: "READ VISOR-BECS CSV REPORTS",
  },
  {
    name: "Minijuegos",
    description: "Recopilación de minijuegos",
    image: totem,
    color: "primary",
    url: `/api/admin/export-minigames-csv?api_token=${token}`,
    grantTo: "READ VISOR-BECS CSV REPORTS",
  },
  {
    name: "Minijuego: Rompecabezas",
    description: "Recopilación de datos del minijuego rompecabezas",
    image: puzzle,
    color: "warning",
    url: `/api/admin/export-puzzle-csv?api_token=${token}`,
    grantTo: "READ VISOR-BECS CSV REPORTS",
  },
  {
    name: "BCS: General",
    description: "Recopilación de datos general de BECS",
    image: becs,
    color: "danger",
    url: `/api/admin/export-becs-csv?api_token=${token}`,
    grantTo: "READ VISOR-BECS CSV REPORTS",
  },
  {
    name: "POV: Resultados",
    description: "Recopilación de datos general de la POV",
    image: vgf,
    color: "usb",
    url: `/api/admin/export-vgf-csv?api_token=${token}`,
    grantTo: "READ VGF CSV REPORTS",
  },
];

const CancelToken = axios.CancelToken;
let source;
let axiosCancelToken;

class Exports extends React.Component {
  state = {
    report: "player",
    form: {
      username: "",
      institution: "",
      group: "",
      scholarship: "",
      communication: "",
      vgfCity: null,
      vgfScholarship: null,
      vgfYear: null,
    },
    fieldErrors: {},
    institutions: [],
    scholarships: [],
    vgfScholarships: [],
    vgfCities: [],
    vgfYears: [
      { id: "1", name: "Último año (Actual)" },
      { id: "2", name: "Año pasado" },
      { id: "3", name: "Hace 2 años" },
    ],
    vgf: {
      reportData: null,
      reportInfo: null,
    },
    reportData: null,
    groups: [
      { id: "institution", name: "Institución" },
      { id: "all", name: "Nacional" },
    ],
    _downloading: false,
    _isLoading: false,
    _isLoadingVGF: false,
  };

  componentDidMount() {
    source = CancelToken.source();
    axiosCancelToken = source.token;

    this.getScholarships();
    this.getInstitutions();
    this.getVGFCities();
  }

  componentWillUnmount() {
    source.cancel();
  }

  getVGFCities = () => {
    client.getVGFCities(axiosCancelToken).then((vgfCities) => {
      vgfCities.push({ id: "all", name: "Todos" });
      this.setState({ vgfCities });
    });
  };

  getScholarships = () => {
    client
      .getScholarships(axiosCancelToken)
      .then((scholarships) => {
        const namesToExtract = ["8", "9", "10", "11", "12", "Graduado"];
        const vgfScholarships = scholarships.filter((item) =>
          namesToExtract.includes(item.name)
        );
        vgfScholarships.push({ id: "all", name: "Todos" });

        this.setState({ scholarships, vgfScholarships });
      })
      .catch((error) => {});
  };

  getInstitutions = () => {
    client
      .getInstitutions(axiosCancelToken)
      .then((institutions) => {
        this.setState({ institutions });
      })
      .catch((error) => {});
  };

  handleReportChange = (report) => {
    this.cleanReportData();

    let groups = [];

    if (report === "player") {
      // Player report
      groups = [
        { id: "institution", name: "Institución" },
        { id: "all", name: "Nacional" },
      ];
    } else {
      // Institution report
      groups = [
        { id: "city", name: "Ciudad" },
        { id: "all", name: "Nacional" },
      ];
    }

    this.setState({ report, groups });
  };

  handleChange = (evt) => {
    const name = evt.target.name;
    const value = evt.target.value;

    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [name]: value,
      },
    }));
  };

  cleanReportData = () => {
    this.setState({
      reportData: null,
      vgf: { reportData: null, reportInfo: null },
    });
  };

  validate = (form) => {
    const errors = {};
    if (this.state.report === "player") {
      if (!form.username) errors.username = "Usuario requerido";
      if (!form.communication) errors.communication = "Campo requerido";
    } else {
      if (!form.institution) errors.institution = "Institución requerida";
      if (!form.scholarship) errors.scholarship = "Grado requerido";
    }
    if (!form.group) errors.group = "Grupo requerido";
    return errors;
  };

  validateVGF = (form) => {
    const errors = {};

    if (!form.vgfCity) errors.vgfCity = "Ciudad requerida";
    if (!form.vgfScholarship) errors.vgfScholarship = "Grado requerido";
    if (!form.vgfYear) errors.vgfYear = "Campo requerido";

    return errors;
  };

  getFormErrors = (messages) => {
    const errors = {};
    for (const key in messages) {
      if (messages.hasOwnProperty(key)) {
        errors[key] = messages[key][0];
      }
    }
    return errors;
  };

  getPlayerReport = () => {
    client
      .getBCSPlayerReportData({ axiosCancelToken, ...this.state.form })
      .then((data) => {
        if (data.ok) {
          this.setState({
            form: {
              username: "",
              institution: "",
              group: "",
              scholarship: "",
              communication: "",
            },
            reportData: data,
          });
        }
      })
      .catch(({ response }) => {
        const fieldErrors = this.getFormErrors(response.data.message);
        this.setState({ fieldErrors, _isLoading: false });
      });
  };

  getInstitutionReport = () => {
    client
      .getBCSInstitutionReportData({ axiosCancelToken, ...this.state.form })
      .then((data) => {
        if (data.ok) {
          this.setState({
            form: {
              username: "",
              institution: "",
              group: "",
              scholarship: "",
              communication: "",
            },
            reportData: data,
          });
        }
      })
      .catch(({ response }) => {
        const fieldErrors = this.getFormErrors(response.data.message);
        this.setState({ fieldErrors, _isLoading: false });
      });
  };

  getVGFReport = () => {
    const { vgfCity, vgfScholarship, vgfYear } = this.state.form;

    client
      .GetVGFGlobalResults(vgfCity, vgfScholarship, vgfYear)
      .then(({ ok, info, results }) => {
        if (ok) {
          this.setState({
            form: {
              vgfCity: null,
              vgfScholarship: null,
              vgfYear: null,
            },
            vgf: {
              reportInfo: info,
              reportData: results,
            },
          });
        }
      })
      .catch(({ response }) => {
        const fieldErrors = this.getFormErrors(response.data.message);
        this.setState({ fieldErrors, _isLoadingVGF: false });
      });
  };

  handleSubmit = () => {
    const form = this.state.form;
    const fieldErrors = this.validate(form);
    this.setState({ fieldErrors });
    if (Object.keys(fieldErrors).length) return;

    this.setState({ _isLoading: true });

    if (this.state.report === "player") {
      this.getPlayerReport();
    } else {
      this.getInstitutionReport();
    }
  };

  handleVGFSubmit = () => {
    const form = this.state.form;
    const fieldErrors = this.validateVGF(form);
    this.setState({ fieldErrors });
    if (Object.keys(fieldErrors).length) return;

    this.setState({ _isLoadingVGF: true });

    this.getVGFReport();
  };

  showNotification = () => {
    this.setState({ _downloading: true });

    setTimeout(() => {
      this.setState({ _downloading: false });
    }, 3000);
  };

  handlePdfDownloaded = () => {
    this.setState({ _isLoading: false, _isLoadingVGF: false });
    this.cleanReportData();
  };

  render() {
    const { classes } = this.props;
    const playerReport = this.state.report === "player";

    return (
      <div>
        <GridContainer>
          {simpleCards(client.token).map((card, key) =>
            !client.permissions.includes(card.grantTo) ? null : (
              <GridItem xs={12} sm={6} md={4} key={key}>
                <Card chart>
                  <CardHeader color={card.color} className={classes.cardHeader}>
                    <img
                      src={card.image}
                      className={classes.cardImage}
                      alt={card.name}
                    />
                  </CardHeader>
                  <CardBody>
                    <h4 className={classes.cardTitle}>{card.name}</h4>
                    <p className={classes.cardCategory}>{card.description}</p>
                  </CardBody>
                  <CardFooter chart>
                    <Button
                      color={card.color}
                      href={card.url}
                      onClick={this.showNotification}
                      disabled={this.state._downloading}
                    >
                      Descargar
                    </Button>
                  </CardFooter>
                </Card>
              </GridItem>
            )
          )}
          {client.permissions.includes("READ VISOR-BECS PDF REPORT") && (
            <GridItem xs={12} sm={6} md={4}>
              <Card chart>
                <CardHeader color="info" className={classes.cardHeader}>
                  BCS REPORTES
                </CardHeader>
                <CardBody>
                  <GridContainer alignItems="center">
                    <GridItem
                      style={{ textAlign: "center" }}
                      xs={12}
                      sm={12}
                      md={12}
                    >
                      <ButtonGroup
                        onChange={this.handleReportChange}
                        value={this.state.report}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      {this.state.report === "player" ? (
                        <CustomInput
                          labelText="Usuario"
                          id="username"
                          value={this.state.form.username}
                          onChange={this.handleChange}
                          formControlProps={{
                            fullWidth: true,
                            style: {
                              margin: "3px 0 10px 0",
                            },
                          }}
                          inputProps={{
                            name: "username",
                            autoComplete: "off",
                          }}
                          error={this.state.fieldErrors.username}
                        />
                      ) : (
                        <CustomSelect
                          labelText="Institución"
                          id="institution"
                          formControlProps={{
                            fullWidth: true,
                            style: {
                              margin: "3px 0 10px 0",
                            },
                          }}
                          selectProps={{
                            name: "institution",
                          }}
                          menuItems={this.state.institutions}
                          value={this.state.form.institution}
                          onChange={this.handleChange}
                          error={this.state.fieldErrors.institution}
                        />
                      )}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <p className={classes.cardCategory}>
                        <b>Grupo de referencia:</b>
                      </p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomSelect
                        labelText="Grupo"
                        id="group"
                        formControlProps={{
                          fullWidth: true,
                          style: {
                            margin: "5px 0 0 0",
                          },
                        }}
                        selectProps={{
                          name: "group",
                        }}
                        menuItems={this.state.groups}
                        value={this.state.form.group}
                        onChange={this.handleChange}
                        error={this.state.fieldErrors.group}
                      />
                    </GridItem>
                    {playerReport ? (
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomSelect
                          labelText="Comunicación"
                          id="communication"
                          formControlProps={{
                            fullWidth: true,
                            style: {
                              margin: "5px 0 0 0",
                            },
                          }}
                          selectProps={{
                            name: "communication",
                          }}
                          menuItems={communications}
                          value={this.state.form.communication}
                          onChange={this.handleChange}
                          error={this.state.fieldErrors.communication}
                        />
                      </GridItem>
                    ) : (
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomSelect
                          labelText="Grado"
                          id="scholarship"
                          formControlProps={{
                            fullWidth: true,
                            style: {
                              margin: "5px 0 0 0",
                            },
                          }}
                          selectProps={{
                            name: "scholarship",
                          }}
                          menuItems={this.state.scholarships}
                          value={this.state.form.scholarship}
                          onChange={this.handleChange}
                          error={this.state.fieldErrors.scholarship}
                        />
                      </GridItem>
                    )}
                  </GridContainer>
                </CardBody>
                <CardFooter chart>
                  {this.state._isLoading ? (
                    <CircularProgress className={classes.blueColor} />
                  ) : (
                    <Button
                      color="info"
                      onClick={this.handleSubmit}
                      disabled={this.state._downloading}
                    >
                      Descargar
                    </Button>
                  )}
                </CardFooter>
              </Card>
            </GridItem>
          )}
          {client.permissions.includes("READ VGF CSV REPORTS") && (
            <GridItem xs={12} sm={6} md={4}>
              <Card chart>
                <CardHeader color="usb" className={classes.cardHeader}>
                  BRÚJULA REPORTES
                </CardHeader>
                <CardBody>
                  <GridContainer alignItems="center">
                    <GridItem xs={12} sm={12} md={12}>
                      <p className={classes.cardCategory}>
                        <b>Grupo de referencia:</b>
                      </p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomSelect
                        labelText="Ciudad"
                        id="vgfCity"
                        formControlProps={{
                          fullWidth: true,
                          style: {
                            margin: "3px 0 10px 0",
                          },
                        }}
                        selectProps={{
                          name: "vgfCity",
                        }}
                        menuItems={this.state.vgfCities}
                        value={this.state.form.vgfCity}
                        onChange={this.handleChange}
                        error={this.state.fieldErrors.vgfCity}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomSelect
                        labelText="Fecha"
                        id="vgfYear"
                        formControlProps={{
                          fullWidth: true,
                          style: {
                            margin: "5px 0 0 0",
                          },
                        }}
                        selectProps={{
                          name: "vgfYear",
                        }}
                        menuItems={this.state.vgfYears}
                        value={this.state.form.vgfYear}
                        onChange={this.handleChange}
                        error={this.state.fieldErrors.vgfYear}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomSelect
                        labelText="Grado"
                        id="vgfScholarship"
                        formControlProps={{
                          fullWidth: true,
                          style: {
                            margin: "5px 0 0 0",
                          },
                        }}
                        selectProps={{
                          name: "vgfScholarship",
                        }}
                        menuItems={this.state.vgfScholarships}
                        value={this.state.form.vgfScholarship}
                        onChange={this.handleChange}
                        error={this.state.fieldErrors.vgfScholarship}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <CardFooter chart>
                  {this.state._isLoadingVGF ? (
                    <CircularProgress className={classes.usbColor} />
                  ) : (
                    <Button
                      color="usb"
                      onClick={this.handleVGFSubmit}
                      disabled={this.state._downloading}
                    >
                      Descargar
                    </Button>
                  )}
                </CardFooter>
              </Card>
            </GridItem>
          )}
          <Snackbar
            place="br" // Bottom Right
            color="success"
            icon={CloudDownload}
            message={"En un momento empezará la descarga"}
            open={this.state._downloading}
            closeNotification={() => this.setState({ _downloading: false })}
            close
          />
        </GridContainer>
        {this.state.reportData && this.state.report === "player" && (
          <div style={{ position: "absolute", overflow: "hidden", height: 0 }}>
            <BCSReport
              player={this.state.reportData.player}
              reference={this.state.reportData.reference_group}
              becsData={this.state.reportData.bcs_scores}
              downloaded={this.handlePdfDownloaded}
            />
          </div>
        )}
        {this.state.reportData &&
          !this.state.vgf.reportInfo &&
          this.state.report === "institution" && (
            <div
              style={{ position: "absolute", overflow: "hidden", height: 0 }}
            >
              <BCSReport
                institution={this.state.reportData.institution}
                reference={this.state.reportData.reference_group}
                becsData={this.state.reportData.bcs_scores}
                downloaded={this.handlePdfDownloaded}
              />
            </div>
          )}
        {this.state.vgf.reportInfo && (
          <div style={{ position: "absolute", overflow: "hidden", height: 0 }}>
            <VGFGlobalReport
              info={this.state.vgf.reportInfo}
              data={this.state.vgf.reportData}
              downloaded={this.handlePdfDownloaded}
            />
          </div>
        )}
      </div>
    );
  }
}

Exports.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(dashboardStyle)(Exports);
