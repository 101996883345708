import React, { Component, Fragment } from "react";
import axios from "axios";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Table from "components/Table/Table.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import AlertDialog from "components/AlertDialog/AlertDialog.jsx";
import UserForm from "../../components/UserForm/UserForm";
// API - Client
import { client } from "../../Client";

const CancelToken = axios.CancelToken;
let source;
let axiosCancelToken;

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class Users extends Component {
  
  state = {
    users: [],
    selectedUser: {},
    fieldErrors: {},
    institutions: [],
    roles: [],
    pagination: {
      count: 0,
      rowsPerPage: 20,
      page: 0
    },
    _showEditDialog: false,
    _showRemoveDialog: false,
    _savedNotification: false,
    _isLoading: false
  }

  componentDidMount() {
    source = CancelToken.source();
    axiosCancelToken = source.token;
    
    this.getUsers()
    this.getInstitutions()
    this.getRoles()
  }

  componentWillUnmount() {
    source.cancel();
  }

  getUsers = () => {
    this.setState({ _isLoading: true })
    client.getUsers(axiosCancelToken,this.state.pagination.rowsPerPage, this.state.pagination.page + 1).then((response) => {

      let users = response.data.map(({ id, username, name, roles, institutions }) => {
        return {
          id,
          username,
          name,
          institution: institutions[0],
          role: roles[0]
        }
      });

      this.setState(prevState => ({
        users,
        pagination: {
          ...prevState.pagination,
          count: response.total
        },
        _isLoading: false
      }));

    }).catch(error => {});
  }

  getInstitutions = () => {
    client.getInstitutions(axiosCancelToken).then(institutions => {
      this.setState({ institutions })
    }).catch(error => {})
  }

  getRoles = () => {
    client.getRoles(axiosCancelToken).then((data) => {
      this.setState({ roles: data })
    }).catch(error => {})
  }
  
  handleChange = (evt) => {
    const name = evt.target.name;
    const value = evt.target.value;

    this.setState(prevState => ({
      selectedUser: {
        ...prevState.selectedUser,
        [name]: value
      }
    }));
  }

  handleCloseDialog = () => {
    this.setState({ _showRemoveDialog: false, _showEditDialog: false })
  }

  /**
   * REMOVE USER FLOW
   */
  onRemove = (user) => {
    this.setState({ _showRemoveDialog: true, selectedUser: user })
  }

  handleRemoveDialog = () => {
    this.removeUser()
    this.setState({ _showRemoveDialog: false })
  }

  removeUser = () => {
    let userId = this.state.selectedUser.id
    client.removeUser(userId).then(({ ok }) => {
      if (ok) {
        this.setState({
          users: this.state.users.filter(({ id }) => id !== userId)
        })
      }
    })
  }

  /**
   * EDIT USER FLOW
   */

  onEdit = (user) => {
    this.setState({
      _showEditDialog: true,
      selectedUser: {
        ...user,
        institution: user.institution.id,
        role: user.role.id
      }
    })
   }

   handleEditDialog = () => {
    this.editUser()
   }

   editUser = () => {
    let selectedUser = this.state.selectedUser
    const fieldErrors = this.validate(selectedUser)
    this.setState({ fieldErrors })
    if( Object.keys(fieldErrors).length ) return;

    client.updateUser(selectedUser).then(({ ok }) => {
      if (ok) {
        this.setState({
          _showEditDialog: false,
          users: this.state.users.map(user => {
            if (user.id === selectedUser.id) {
              delete selectedUser['password']
              return {
                ...selectedUser,
                institution: this.state.institutions.find(({ id }) => id === selectedUser.institution),
                role: this.state.roles.find(({ id }) => id === selectedUser.role)
              }
            }
            return user
          })
        })
      }
    }).catch(({ response }) => {
      const fieldErrors = this.getFormErrors(response.data.message)
      this.setState({ fieldErrors })
    })
   }

   validate = (form) => {
    const errors = {};
    if (!form.username) errors.username = 'Usuario requerido';
    if (!form.name) errors.name = 'Nombre requerido';
    if (!form.institution) errors.institution = 'Institución requerida';
    if (!form.role) errors.role = 'Rol requerido';
    return errors;
  };

  getFormErrors = (messages) => {
    const errors = {}
    for (const key in messages) {
      if (messages.hasOwnProperty(key)) {
        errors[key] = messages[key][0]        
      }
    }
    return errors
  }

  // PAGINATION
  handleChangePage = (evt, page) => {
    this.setState(prevState => ({
      pagination: {
        ...prevState.pagination,
        page
      }
    }), () => this.getUsers() )
  }

   handleChangeRowsPerPage = (evt) => {    
    this.setState(prevState => ({
      pagination: {
        ...prevState.pagination,
        rowsPerPage: evt.target.value
      }
    }), () => this.getUsers() )
  }
  
  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="warning">
                <h4 className={classes.cardTitleWhite}>Administradores</h4>
                <p className={classes.cardCategoryWhite}>
                  Listado de administradores registrados
                </p>
              </CardHeader>
              <CardBody>
                <Table
                  tableHeaderColor="warning"
                  tableHead={["ID", "Usuario", "Nombre", "Institución", "Rol"]}
                  tableData={this.state.users}
                  onRemove={this.onRemove}
                  onEdit={this.onEdit}
                  paginationProps={{
                    rowsPerPageOptions: [10, 15, 20, 25],
                    count: this.state.pagination.count,
                    rowsPerPage: this.state.pagination.rowsPerPage,
                    page: this.state.pagination.page,
                    onChangePage: this.handleChangePage,
                    onChangeRowsPerPage: this.handleChangeRowsPerPage
                  }}
                  loading={this.state._isLoading}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <AlertDialog
          open={this.state._showRemoveDialog}
          onClose={this.handleCloseDialog}
          onAgree={this.handleRemoveDialog}
          dialogTitle={`${this.state.selectedUser.username}`}
          dialogDescription={`¿Está seguro que desea eliminar este administrador?. Esta acción es irreversible`}
          remove
        />
        <AlertDialog
          open={this.state._showEditDialog}
          onClose={this.handleCloseDialog}
          onAgree={this.handleEditDialog}
          dialogTitle={`${this.state.selectedUser.username}`}
          dialogContent={
            <UserForm form={this.state.selectedUser} onChange={this.handleChange} 
                  institutions={this.state.institutions} roles={this.state.roles} fieldErrors={this.state.fieldErrors} />
          }
          dialogProps={{
            fullWidth: true,
            maxWidth: "md"
          }}
        />
      </Fragment>
    );
  }
}

export default withStyles(styles)(Users);
