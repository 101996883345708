import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomDatepicker from "components/CustomDatepicker/CustomDatepicker.jsx";
import Autocomplete from "@material-ui/lab/Autocomplete";

export default function VGFTestForm(props) {

  const { form, onChange, institutions, scholarships, fieldErrors } = props;

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <Autocomplete
            value={ form.institution }
            onChange={(e, value) => {
              const mockEvent = { target: { name: "institution", value } }
              onChange(mockEvent);
            }}
            id="institution"
            options={institutions}
            getOptionLabel={(option) => option.name}
            renderInput={({ InputLabelProps, InputProps, ...restRender }) => (
              <CustomInput
                labelText="Institución"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  ...InputProps,
                  ...restRender,
                  name: "institution",
                  autoComplete: "off"
                }}
              />
            )}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <CustomSelect
            labelText="Escolaridad"
            id="scholarship"
            formControlProps={{
              fullWidth: true
            }}
            selectProps={{
              name: "scholarship"
            }}
            menuItems={scholarships}
            value={ form.scholarship }
            onChange={onChange}
            error={fieldErrors.scholarship}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <CustomInput
            labelText="N° de Estudiantes"
            id="total_students"
            value={form.total_students}
            onChange={onChange}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              name: "total_students",
              autoComplete: "off"
            }}
            error={fieldErrors.total_students}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <CustomInput
            labelText="Tickets iniciales"
            id="tickets"
            value={form.tickets}
            onChange={onChange}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              name: "tickets",
              autoComplete: "off",
              type: "number"
            }}
            error={fieldErrors.tickets}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <CustomDatepicker
            labelText="Fecha de aplicación"
            id="date"
            value={form.date}
            onChange={onChange}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              name: "date"
            }}
            error={fieldErrors.date}
          />
        </GridItem>
      </GridContainer>
    </div>
  )
}