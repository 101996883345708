import React, { Component, Fragment } from "react";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";

class InstitutionForm extends Component {
  render() {
    const { form, onChange, cities, types, fieldErrors } = this.props
    return (
      <Fragment>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <CustomInput
              labelText="Nombre"
              id="name"
              value={form.name}
              onChange={onChange}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "name",
                autoComplete: "off"
              }}
              error={fieldErrors.name}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <CustomInput
              labelText="Responsable"
              id="responsible"
              value={form.responsible}
              onChange={onChange}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "responsible"
              }}
              error={fieldErrors.responsible}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <CustomInput
              labelText="Teléfono"
              id="phone"
              value={form.phone}                      
              onChange={onChange}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "phone"
              }}
              error={fieldErrors.phone}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <CustomInput
              labelText="Correo"
              id="email"
              value={form.email}
              onChange={onChange}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: "email"
              }}
              error={fieldErrors.email}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <CustomSelect
              labelText="Ciudad"
              id="city"
              formControlProps={{
                fullWidth: true
              }}
              selectProps={{
                name: "city"
              }}
              menuItems={cities}
              value={form.city}
              onChange={onChange}
              error={fieldErrors.city}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <CustomSelect
              labelText="Tipo de institución"
              id="type"
              formControlProps={{
                fullWidth: true
              }}
              selectProps={{
                name: "type"
              }}
              menuItems={types}
              value={form.type}
              onChange={onChange}
              error={fieldErrors.type}
            />
          </GridItem>
        </GridContainer>
      </Fragment>
    )
  }
}

export default InstitutionForm