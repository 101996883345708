import React, { Component } from "react";
import axios from "axios";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import InstitutionForm from "components/InstitutionForm/InstitutionForm.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
// API - Client
import { client } from "../../Client";
//Helpers
import { isEmail } from "../../Helpers";

const CancelToken = axios.CancelToken;
let source;
let axiosCancelToken;

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

class AddInstitution extends Component {
  state ={
    form: {
      name: "",
      responsible: "",
      phone: "",
      email: "",
      city: "",
      type: ""
    },
    fieldErrors: {},
    cities: [],
    types: [],
    _savedNotification: false
  }

  componentDidMount() {
    source = CancelToken.source();
    axiosCancelToken = source.token;
    
    this.getCities()
    this.getInstitutionTypes()
  }

  componentWillUnmount() {
    source.cancel();
  }

  getCities = () => {
    client.getCities(axiosCancelToken).then(cities => {
      this.setState({ cities })
    }).catch(error => {})
  }

  getInstitutionTypes = () => {
    client.getInstitutionTypes(axiosCancelToken).then(types => {
      this.setState({ types })
    }).catch(error => {})
  }

  handleChange = (evt) => {
    const name = evt.target.name;
    const value = evt.target.value;

    this.setState(prevState => ({
      form: {
        ...prevState.form,
        [name]: value
      }
    }));
  }

  validate = (form) => {
    const errors = {};
    if (!form.name) errors.name = 'Nombre requerido';
    if (!form.responsible) errors.responsible = 'Responsable requerido';
    if (!form.phone) errors.phone = 'Teléfono requerido';
    if (!form.email) errors.email = 'Correo requerido';
    if (!form.city) errors.city = 'Ciudad requerida';
    if (!form.type) errors.type = 'Tipo requerido';
    if (form.email && !isEmail(form.email)) errors.email = 'Correo inválido';
    return errors;
  };

  getFormErrors = (messages) => {
    const errors = {}
    for (const key in messages) {
      if (messages.hasOwnProperty(key)) {
        errors[key] = messages[key][0]        
      }
    }
    return errors
  }

  handleSubmit = () => {
    const form = this.state.form;
    const fieldErrors = this.validate(form)
    this.setState({ fieldErrors })
    if( Object.keys(fieldErrors).length ) return;

    client.AddInstitution(form).then(({ data }) => {
      if ( data.ok ) {
        this.setState(prevState => ({
          form: {
            name: "",
            responsible: "",
            phone: "",
            email: "",
            city: "",
            type: ""
          },
          _lastAdded: prevState.form.name
        }))

        this.showNotification()
      }
    }).catch(({ response }) => {
      const fieldErrors = this.getFormErrors(response.data.message)
      this.setState({ fieldErrors })
    })
  }

  showNotification = () => {
    this.setState({ _savedNotification: true })

    setTimeout(() => {
      this.setState({ _savedNotification: false })
    }, 3000)
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success">
                <h4 className={classes.cardTitleWhite}>Nueva institución</h4>
                <p className={classes.cardCategoryWhite}>Complete la información y guarde para añadir una nueva institución</p>
              </CardHeader>
              <CardBody>
                <InstitutionForm form={this.state.form} onChange={this.handleChange} cities={this.state.cities}
                  types={this.state.types} fieldErrors={this.state.fieldErrors} />
              </CardBody>
              <CardFooter>
                <Button color="success" onClick={this.handleSubmit} disabled={this.state.form.name === ""}>Guardar</Button>
                <Snackbar
                  place="br" // Bottom Right
                  color="success"
                  icon={CheckCircleOutline}
                  message={`'${this.state._lastAdded}' fue agregado correctamente!`}
                  open={this.state._savedNotification}
                  closeNotification={() => this.setState({ _savedNotification: false })}
                  close
                />
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(styles)(AddInstitution);
