import React, { Component } from "react";
import axios from "axios";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import VGFInstitutionForm from "components/VGFInstitutionForm/VGFInstitutionForm.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
// API - Client
import { client } from "../../Client";
//Helpers
import { isEmail } from "../../Helpers";
import VGFTestForm from "components/VGFTestForm/VGFTestForm";

const CancelToken = axios.CancelToken;
let source;
let axiosCancelToken;

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

const terms = ['A','B'];

class AddVGFInstitution extends Component {
  state ={
    form: {
      name: "",
      responsible: "",
      phone: "",
      email: "",
      department: "",
      city: ""
    },
    form2: {
      institution: null,
      scholarship: "",
      total_students: "",
      tickets: 0,
      date: "",
    },
    fieldErrors: {},
    institutions: [],
    scholarships: [],
    departments: [],
    cities: [],
    _savedNotification: false,
    _lastAdded: ""
  }

  componentDidMount() {
    source = CancelToken.source();
    axiosCancelToken = source.token;
    
    this.getDepartments()
    this.getInstitutions()
    this.getScholarships()
  }

  componentWillUnmount() {
    source.cancel();
  }

  getDepartments = () => {
    client.getDepartments(axiosCancelToken).then(departments => {
      this.setState({ departments })
    }).catch(error => {})
  }

  getCities = (department) => {

    this.setState(prevState => ({
      form: {
        ...prevState.form,
        city: ""
      }
    }));
    
    client.getCitiesByDepartment(axiosCancelToken, department).then(cities => {
      this.setState({ cities })
    }).catch(error => {})
  }

  getInstitutions = () => {
    client.getAllVGFInstitutions(axiosCancelToken).then(institutions => {
      this.setState({ institutions })
    }).catch(error => {})
  }

  getScholarships = () => {
    client.getVGFScholarships(axiosCancelToken).then(scholarships => {
      this.setState({ scholarships })
    }).catch(error => {})
  }

  handleChange = (evt) => {
    const name = evt.target.name;
    const value = evt.target.value;

    if(name === "department") this.getCities(value);

    this.setState(prevState => ({
      form: {
        ...prevState.form,
        [name]: value
      }
    }));
  }

  handleChange2 = (evt) => {
    const name = evt.target.name;
    const value = evt.target.value;

    this.setState(prevState => ({
      form2: {
        ...prevState.form2,
        [name]: value
      }
    }));
  }

  validate = (form) => {
    const errors = {};
    if (!form.name) errors.name = 'Nombre requerido';
    if (!form.responsible) errors.responsible = 'Responsable requerido';
    if (!form.phone) errors.phone = 'Teléfono requerido';
    if (!form.email) errors.email = 'Correo requerido';
    if (!form.term) errors.term = 'Calendario requerido';
    if (!form.department) errors.department = 'Departamento requerido';
    if (!form.city) errors.city = 'Ciudad requerida';
    if (form.email && !isEmail(form.email)) errors.email = 'Correo inválido';
    return errors;
  };

  validate2 = (form) => {
    const errors = {};
    if (!form.institution) errors.institution = 'Institución requerida';
    if (!form.scholarship) errors.scholarship = 'Escolaridad requerida';
    if (!form.total_students) errors.total_students = 'N° de Estudiantes requerido';
    if (!form.tickets) errors.tickets = 'N° de Tickets requerido';
    if (form.tickets < 0) errors.tickets = 'N° de Tickets debe ser positivo';
    if (!form.date) errors.date = 'Fecha de aplicación requerida';
    return errors;
  };

  getFormErrors = (messages) => {
    const errors = {}
    for (const key in messages) {
      if (messages.hasOwnProperty(key)) {
        errors[key] = messages[key][0]        
      }
    }
    return errors
  }

  handleSubmit = () => {
    const form = this.state.form;
    const fieldErrors = this.validate(form)
    this.setState({ fieldErrors })
    if( Object.keys(fieldErrors).length ) return;

    client.AddVGFInstitution(form).then(({ data }) => {
      if ( data.ok ) {
        this.setState(prevState => ({
          form: {
            name: "",
            responsible: "",
            phone: "",
            email: "",
            term: "",
            department: "",
            city: ""
          },
          _lastAdded: prevState.form.name
        }))

        this.showNotification()
      }
    }).catch(({ response }) => {
      const fieldErrors = this.getFormErrors(response.data.message)
      this.setState({ fieldErrors })
    })
  }

  handleSubmit2 = () => {
    const form = this.state.form2;
    const fieldErrors = this.validate2(form)
    this.setState({ fieldErrors })
    if( Object.keys(fieldErrors).length ) return;

    client.AddVGFTest({...form, institution: form.institution.id}).then(({ data }) => {
      if ( data.ok ) {
        this.setState(prevState => ({
          form2: {
            institution: null,
            scholarship: "",
            total_students: "",
            tickets: 0,
            date: "",
          },
        }))

        this.sendEmail(data.test_id);
        this.showNotification()
      }
    }).catch(({ response }) => {
      const fieldErrors = this.getFormErrors(response.data.message)
      this.setState({ fieldErrors })
    });
  }

  sendEmail = (testId) => {
    client.sendInstitutionCodeByEmail(testId);
  }

  showNotification = () => {
    this.setState({ _savedNotification: true })

    setTimeout(() => {
      this.setState({ _savedNotification: false })
    }, 3000)
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="danger">
                <h4 className={classes.cardTitleWhite}>Nuevo Grupo</h4>
                <p className={classes.cardCategoryWhite}>Complete la información y guarde para añadir un nuevo grupo de aplicación</p>
              </CardHeader>
              <CardBody>
                <VGFTestForm form={this.state.form2} onChange={this.handleChange2} institutions={this.state.institutions}
                scholarships={this.state.scholarships} fieldErrors={this.state.fieldErrors} />
              </CardBody>
              <CardFooter>
                <Button color="danger" onClick={this.handleSubmit2}>Guardar</Button>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success">
                <h4 className={classes.cardTitleWhite}>Nueva institución</h4>
                <p className={classes.cardCategoryWhite}>Complete la información y guarde para añadir una nueva institución</p>
              </CardHeader>
              <CardBody>
                <VGFInstitutionForm form={this.state.form} onChange={this.handleChange} terms={terms} 
                departments={this.state.departments} cities={this.state.cities} fieldErrors={this.state.fieldErrors} />
              </CardBody>
              <CardFooter>
                <Button color="success" onClick={this.handleSubmit} disabled={this.state.form.name === ""}>Guardar</Button>
                <Snackbar
                  place="br" // Bottom Right
                  color="success"
                  icon={CheckCircleOutline}
                  message={`Tu registro fue agregado correctamente!`}
                  open={this.state._savedNotification}
                  closeNotification={() => this.setState({ _savedNotification: false })}
                  close
                />
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(styles)(AddVGFInstitution);
