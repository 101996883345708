import React from "react"
import Button from "components/CustomButtons/Button.jsx"

import buttonGroupStyle from "assets/jss/material-dashboard-react/components/buttonGroupStyle.jsx"
import { withStyles } from "@material-ui/core"

function ButtonGroup({ ...props }) {
  const { classes, onChange, value } = props
  return (
    <div className={classes.buttonGroup}>
      <Button color={value === 'player'? 'info':'lightInfo'} className={classes.firstButton} onClick={() => onChange('player')} >
        Estudiante
      </Button>
      <Button color={value === 'institution'? 'info':'lightInfo'} className={classes.lastButton} onClick={() => onChange('institution')} >
        Institución
      </Button>
    </div>
  )
}

export default withStyles(buttonGroupStyle)(ButtonGroup)
